import Feature from 'ol/Feature';
import { boundingExtent, getCenter } from 'ol/extent';
import { fromExtent } from 'ol/geom/Polygon';

import message from 'antd/es/message';

import { GEOMETRY_TYPE_STRING } from '../../../Constants/Constant';
import { Observer } from '../../../Utils/Observer';
import { layerTracker, outputMap } from '../MapInit';
import { TOOL_EVENT } from '../../Output/Toolbar/ToolController';
import { applyLayerStyleToFeature } from '../../../Utils/olutils';
import { useRequest } from '../../../Stores/Request';

class PasteFeatures extends Observer {
    aerialLotExtent: $TSFixMe;

    aerialLotFeature: $TSFixMe;

    bpLotExtent: $TSFixMe;

    bpLotFeature: $TSFixMe;

    invalidSpace: $TSFixMe;

    mapObj: $TSFixMe;

    constructor(mapObj: $TSFixMe) {
        super();
        this.mapObj = mapObj;
        this.invalidSpace = false;
        this.bpLotExtent = null;
        this.bpLotFeature = null;
        this.aerialLotExtent = null;
        this.aerialLotFeature = null;
    }

    on({ shortcutKey = false }) {
        this.off();

        const { selectedFeatures, clipboardFeatures } = useRequest.getState() || {};

        if (!clipboardFeatures || !Object.keys(clipboardFeatures).length) {
            return null;
        }

        const isBlueprint = this.mapObj.isBlueprintMap;

        const screenExtent = this.mapObj.getMapExtent();

        if (isBlueprint) {
            this.bpLotExtent = this.mapObj.baseLayer.get('bp_page_extent');
            const polygon = fromExtent(this.bpLotExtent);
            this.bpLotFeature = new Feature(polygon);
        } else {
            this.aerialLotExtent = this.mapObj.getParcelLayer().getSource().getExtent();
        }
        const lotExtent = isBlueprint ? this.bpLotExtent : this.aerialLotExtent;

        let targetCoord: $TSFixMe;

        if (shortcutKey) {
            targetCoord = [...outputMap.ptrCoord];
        } else if (isBlueprint) {
            targetCoord = [
                screenExtent[0] < lotExtent[0] ? lotExtent[0] + 1 : screenExtent[0] + 1,
                screenExtent[3] > lotExtent[3] ? lotExtent[3] - 1 : screenExtent[3] - 1
            ];
        } else {
            targetCoord = getCenter(screenExtent);
        }

        this.invalidSpace = !this.mapObj.coordsExistsInParcel(
            targetCoord,
            isBlueprint ? this.bpLotFeature : this.aerialLotFeature
        );

        if (this.invalidSpace) {
            return message.error('Unable to paste feature outside the lot boundary.');
        }

        // @ts-expect-error TS(2339): Property 'isCopied' does not exist on type 'never'... Remove this comment to see the full error message
        const isCopied = clipboardFeatures?.isCopied;
        let totalFeaturesToPaste = 0;
        let notPastedFeatsCount = 0;

        Object.keys(clipboardFeatures).forEach(layerId => {
            const targetLayer = this.mapObj.getLayerById(layerId);
            if (!targetLayer) {
                if (clipboardFeatures) {
                    delete clipboardFeatures[layerId];
                }
                return message.error('Layer not found');
            }
            const source = targetLayer.getSource();
            let slideDown = 0;
            const OFFSET = 100;

            // @ts-expect-error TS(2339): Property 'forEach' does not exist on type 'never'.
            clipboardFeatures[layerId].forEach((feature: $TSFixMe) => {
                totalFeaturesToPaste++;

                // applying original style back to the selected feature.
                applyLayerStyleToFeature({ layer: targetLayer, feature });

                let copy;
                if (isCopied) {
                    copy = feature.clone();
                    source.addFeature(copy);
                    copy.set('id', source.getFeatures().length);
                    feature = copy;
                }

                const geom = feature.getGeometry();

                const geomType = geom.getType();

                const featureExtent = geom.getExtent();

                const featureCenter = getCenter(featureExtent);

                let offsetX;
                let offsetY;
                if (shortcutKey) {
                    [offsetX, offsetY] = [targetCoord[0] - featureCenter[0], targetCoord[1] - featureCenter[1]];
                } else {
                    [offsetX, offsetY] = [
                        targetCoord[0] - featureExtent[0] + slideDown,
                        targetCoord[1] - featureExtent[3] - slideDown
                    ];
                }

                // Adjust the coordinates of the feature's geometry
                const originalCoords = geom.getCoordinates();
                let coords = this.getPasteFeatCoords(geomType, originalCoords, offsetX, offsetY);

                // handling out_of_extent_case
                if ([GEOMETRY_TYPE_STRING.POLYGON, GEOMETRY_TYPE_STRING.LINESTRING].includes(geomType)) {
                    const coordArr = geomType === GEOMETRY_TYPE_STRING.POLYGON ? coords[0] : coords;

                    for (let k = 0; k < coordArr.length; k++) {
                        const coord = coordArr[k];
                        if (
                            !this.mapObj.coordsExistsInParcel(
                                coord,
                                isBlueprint ? this.bpLotFeature : this.aerialLotFeature
                            )
                        ) {
                            if (isBlueprint) {
                                const newFeatExt = boundingExtent(coordArr);
                                if (shortcutKey) {
                                    if (newFeatExt[0] < lotExtent[0]) {
                                        offsetX -= newFeatExt[0] - lotExtent[0];
                                    }
                                    if (newFeatExt[1] < lotExtent[1]) {
                                        offsetY -= newFeatExt[1] - lotExtent[1];
                                    }
                                    if (newFeatExt[2] > lotExtent[2]) {
                                        offsetX -= newFeatExt[2] - lotExtent[2];
                                    }
                                    if (newFeatExt[3] > lotExtent[3]) {
                                        offsetY -= newFeatExt[3] - lotExtent[3];
                                    }
                                } else {
                                    if (newFeatExt[2] > lotExtent[2]) {
                                        offsetX = offsetX - (newFeatExt[2] - lotExtent[2]) - slideDown;
                                    }
                                    if (newFeatExt[1] < lotExtent[1]) {
                                        offsetY = offsetY - (newFeatExt[1] - lotExtent[1]) + slideDown;
                                    }
                                }

                                coords = this.getPasteFeatCoords(geomType, originalCoords, offsetX, offsetY);
                                break;
                            } else {
                                notPastedFeatsCount++;
                                if (copy) {
                                    source.removeFeature(copy);
                                }
                                return message.error('Unable to paste the feature outside the lot boundary.');
                            }
                        }
                    }
                }

                geom.setCoordinates(coords);

                // applySelectStyleToFeature(feature);
                if (
                    !shortcutKey &&
                    featureExtent[1] > lotExtent[1] + OFFSET &&
                    featureExtent[2] < lotExtent[2] - OFFSET
                )
                    slideDown += OFFSET;

                // Push layer in tracker
                layerTracker.push(this.mapObj.getLayerName(layerId), layerId);
                return null;
            });
            return null;
        });

        // if any of the one feature is pasted successfully
        if (notPastedFeatsCount !== totalFeaturesToPaste) {
            const presentInClipboard = (sf: $TSFixMe) => {
                const sfLayerId = sf.get('layerId');
                if (clipboardFeatures[sfLayerId]) {
                    // @ts-expect-error TS(2339): Property 'some' does not exist on type 'never'.
                    const isFeatureInClipboard = clipboardFeatures[sfLayerId]?.some(
                        (feature: $TSFixMe) => feature.get('id') === sf.get('id')
                    );
                    if (isFeatureInClipboard) {
                        return true;
                    }
                }
                return false;
            };
            // filtering out selectefFeatures after paste operation is done
            // by removing the features which are pasted.
            const payload: $TSFixMe = [];
            selectedFeatures.forEach(sf => {
                // if feature is present in clipboard then its already pasted, and we'll skip it
                if (!presentInClipboard(sf)) {
                    payload.push(sf);
                }
            });
            this.notifyObservers(TOOL_EVENT.SELECT_FEATURES, payload);

            // if user has made the cut operation
            // converting it to copy so that when user can paste the same thing again without deleting original one
            if (!isCopied) {
                const proto = Object.create(Object.getPrototypeOf(clipboardFeatures));
                const newClipBoard = Object.assign(clipboardFeatures, proto);
                // @ts-expect-error TS(2339): Property '__proto__' does not exist on type 'never... Remove this comment to see the full error message
                newClipBoard.__proto__.isCopied = true;
                setTimeout(() => this.notifyObservers(TOOL_EVENT.LOAD_CLIPBOARD, newClipBoard), 0);
            }

            // delaying since SELECT_TOOL event dispatched before adding the pasted feature to backend
            if (layerTracker.getArray().length) {
                setTimeout(() => this.notifyObservers(TOOL_EVENT.PASTE_FEATURES), 0);
            }
        }
        return null;
    }

    getPasteFeatCoords = (geomType: $TSFixMe, originalCoords: $TSFixMe, offsetX: $TSFixMe, offsetY: $TSFixMe) => {
        switch (geomType) {
            case GEOMETRY_TYPE_STRING.POLYGON:
                return originalCoords.map((ring: $TSFixMe) =>
                    ring.map((coordinate: $TSFixMe) => [coordinate[0] + offsetX, coordinate[1] + offsetY])
                );
            case GEOMETRY_TYPE_STRING.LINESTRING:
                return originalCoords.map((coordinate: $TSFixMe) => [coordinate[0] + offsetX, coordinate[1] + offsetY]);
            case GEOMETRY_TYPE_STRING.POINT:
                return [originalCoords[0] + offsetX, originalCoords[1] + offsetY];
            default:
                return null;
        }
    };

    off() {
        this.bpLotExtent = null;
        this.bpLotFeature = null;
        this.aerialLotExtent = null;
        this.aerialLotFeature = null;
    }
}

export default PasteFeatures;
