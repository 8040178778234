import { captureException } from '@sentry/react';

import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import Text from 'ol/style/Text';
import Style from 'ol/style/Style';
import Point from 'ol/geom/Point';
import Feature from 'ol/Feature';
import { fromLonLat, toLonLat } from 'ol/proj';
import { Icon } from 'ol/style';

import {
    BP_PREFIX,
    DEFAULT_EMOJI_UNICODE,
    DEFAULT_NOTE_ICONS,
    DEFAULT_NOTE_UNICODES,
    LAYER_INDEX,
    MAP_LAYERS,
    TOOLS_ID
} from '../../../Constants/Constant';
import { getAPI, interpolate, patchAPI, openAPI, postAPI } from '../../../Utils/ApiCalls';
import {
    BLUEPRINT_SHARED_REQUEST_NOTE,
    BLUEPRINT_SHARED_REQUEST_NOTES,
    REQUEST_NOTE,
    REQUEST_NOTES,
    SHARED_REQUEST_NOTE,
    SHARED_REQUEST_NOTES
} from '../../../Constants/Urls';
import { adjustOverlayPosition, changeMapCursor } from '../../../Utils/HelperFunctions';
import { outputMap, toolController } from '../MapInit';
import { useRequest } from '../../../Stores/Request';

class Notes {
    addCoord: $TSFixMe;

    domElements: $TSFixMe;

    isSharedView: $TSFixMe;

    mapObj: $TSFixMe;

    notesLayer: $TSFixMe;

    notesVisibility: $TSFixMe;

    requestId: $TSFixMe;

    selectedFeature: $TSFixMe;

    constructor(mapObj: $TSFixMe) {
        this.mapObj = mapObj;
        this.notesLayer = null;
        this.domElements = null;
        this.selectedFeature = null; // Current Selected Feature
        this.requestId = null; // Output Request ID or Sharedview Request ID
        this.isSharedView = false; // Is tool running on sharedview
        this.addCoord = []; // Coordinate where new note will be added
        this.notesVisibility = true;
    }

    on({ requestId, isSharedView = false }: $TSFixMe) {
        this.isSharedView = isSharedView;

        this.mapObj.map.on('singleclick', this.onMapClick);

        this.requestId = requestId;
        this.domElements = {
            container: 'notes-container',
            addNotesContainer: 'add-notes',
            viewNotesContainer: 'view-notes',
            viewNotesText: 'view-notes-text',
            deleteLoadingBtn: 'delete-loading-btn',
            deleteBtn: 'delete-btn',
            input: 'add-notes-input',
            delete: 'note-delete',
            edit: 'note-edit',
            viewImagesContainer: 'view-images-container',
            uploadImagesContainer: 'upload-images-container',
            emojiContainer: 'emoji-container'
        };
    }

    getIsAddingNote() {
        return useRequest.getState()?.toolbar?.isAddingNote;
    }

    // This function is called on loading and renders all the notes
    addNotes(notes: $TSFixMe) {
        try {
            if (this.notesLayer) {
                this.mapObj.removeLayer(this.notesLayer);
            }
            this.notesLayer = new VectorLayer({
                source: new VectorSource({ wrapX: false }),
                // @ts-expect-error TS(2345): Argument of type '{ source: VectorSource<Geometry>... Remove this comment to see the full error message
                id: MAP_LAYERS.NOTES,
                name: MAP_LAYERS.NOTES,
                zIndex: LAYER_INDEX.NOTES,
                layerData: { name: MAP_LAYERS.NOTES },
                style: feature => this.getFeatureStyle(feature.get('noteData'))
            });
            this.mapObj.addLayer(this.notesLayer);
            this.notesLayer.setVisible(this.notesVisibility);

            if (notes.length) {
                for (let i = 0; i < notes.length; i++) {
                    this.addNote(notes[i]);
                }
            }
        } catch (err) {
            captureException(err);
        }
    }

    loadNotes = async ({ requestId, worksheetId = '' }: $TSFixMe) => {
        const url = interpolate(
            this.isSharedView
                ? this.mapObj.isBlueprintMap
                    ? BLUEPRINT_SHARED_REQUEST_NOTES
                    : SHARED_REQUEST_NOTES
                : REQUEST_NOTES,
            [requestId]
        );
        const params = this.mapObj.isBlueprintMap ? { worksheet_id: worksheetId } : {};
        const apiCall = this.isSharedView
            ? openAPI(url, {}, 'GET', {}, params)
            : getAPI(url, {
                  prefix: this.mapObj.isBlueprintMap ? BP_PREFIX : '',
                  params
              });
        return apiCall.then(res => Array.isArray(res) && this.addNotes(res));
    };

    // Renders the newly added notes
    addNote(noteData: $TSFixMe) {
        const feature = new Feature({
            geometry: new Point(fromLonLat([noteData.lon, noteData.lat])),
            id: noteData.id,
            noteData,
            isNote: true
        });
        this.notesLayer.getSource().addFeature(feature);
    }

    // Set the icon style for each note/feature.
    getFeatureStyle(noteData: $TSFixMe) {
        let style;
        // If the note icon is a default icon then we will set the feature style as a image otherwise
        // it will be set as a text.
        if (Object.prototype.hasOwnProperty.call(DEFAULT_NOTE_ICONS, noteData.icon_emoji_unicode))
            style = new Style({
                image: new Icon({
                    src:
                        DEFAULT_NOTE_ICONS[noteData.icon_emoji_unicode] ||
                        DEFAULT_NOTE_ICONS[DEFAULT_NOTE_UNICODES.NOTE],
                    anchor: [0.5, 1],
                    scale: 0.3,
                    crossOrigin: 'anonymous'
                })
            });
        else {
            // We have used text instead of icon as we are using the unicode to render the corresponding
            // emoji. The String.fromCodePoint() function takes the unicode and returns a string which is
            // rendered as an emoji on the map.
            style = new Style({
                text: new Text({
                    text: String.fromCodePoint(parseInt(noteData.icon_emoji_unicode || DEFAULT_EMOJI_UNICODE, 16)),
                    scale: 3.2
                })
            });
        }

        return style;
    }

    changeNoteTitle(title: $TSFixMe) {
        const titleNode = document.getElementById('note-page-title');
        if (titleNode) titleNode.innerText = title;
    }

    showContainerAt(pageX: $TSFixMe, pageY: $TSFixMe) {
        const container = document.getElementById(this.domElements.container);
        if (container) {
            container.style.display = 'block';
            const { offsetWidth: overlayWidth, offsetHeight: overlayHeight } = container || {};

            const [positionX, positionY] = adjustOverlayPosition({
                pageX,
                pageY,
                overlayWidth,
                overlayHeight
            });
            // @ts-expect-error TS(2345): Argument of type 'number' is not assignable to par... Remove this comment to see the full error message
            container.style.left = `${parseInt(positionX, 10)}px`;
            // @ts-expect-error TS(2345): Argument of type 'number' is not assignable to par... Remove this comment to see the full error message
            container.style.top = `${parseInt(positionY, 10)}px`;
        }
    }

    hideContainer() {
        const container = document.getElementById(this.domElements.container);
        if (container) container.style.display = 'none';
    }

    addNotesContainer({ show, text = '', unicode = '' }: $TSFixMe) {
        if (show) this.viewNotesContainer({ show: false });

        const addContainer = document.getElementById(this.domElements.addNotesContainer);
        if (addContainer) addContainer.style.display = show ? 'block' : 'none';

        const input = document.getElementById(this.domElements.input);
        if (input) {
            // @ts-expect-error TS(2339): Property 'value' does not exist on type 'HTMLEleme... Remove this comment to see the full error message
            input.value = text;
        }
        // If the user edits the note then we populate the current notes emoji at the first position
        // in the tab bar
        const emoji = document.getElementById(this.domElements.emojiContainer);
        if (unicode && emoji) {
            emoji.innerText = Object.prototype.hasOwnProperty.call(DEFAULT_NOTE_ICONS, unicode)
                ? String.fromCodePoint(parseInt(DEFAULT_EMOJI_UNICODE, 16))
                : String.fromCodePoint(parseInt(unicode, 16));
            emoji.style.fontSize = '18px';
        }
        this.changeNoteTitle(text ? 'Edit note' : 'Add a note');
    }

    viewNotesContainer({ show, text = '', images = [] }: $TSFixMe) {
        if (show) this.addNotesContainer({ show: false });

        const viewContainer = document.getElementById(this.domElements.viewNotesContainer);
        if (viewContainer) viewContainer.style.display = show ? 'block' : 'none';

        const viewNotesText = document.getElementById(this.domElements.viewNotesText);
        if (viewNotesText) {
            viewNotesText.style.whiteSpace = 'pre-line';
            viewNotesText.innerHTML = text;
        }

        // Render the uploaded images in the view notes modal
        const viewImagesContainer = document.getElementById(this.domElements.viewImagesContainer);
        if (!viewImagesContainer) return;

        if (images?.length) {
            viewImagesContainer.style.display = 'flex';
            // @ts-expect-error TS(7006): Parameter 'file' implicitly has an 'any' type.
            images.forEach(file => {
                const imageContainer = document.createElement('div');
                imageContainer.className = 'upload-file';

                const image = document.createElement('img');
                image.src = file.media;
                image.className = 'uploaded-image';

                const link = document.createElement('a');
                link.className = 'upload-filename';
                link.innerHTML = file.file_name;
                link.href = file.media;

                imageContainer.append(image);
                imageContainer.append(link);
                viewImagesContainer.append(imageContainer);
            });
        } else {
            viewImagesContainer.style.display = 'none';
        }
    }

    onMapClick = (e: $TSFixMe) => {
        const activeTool = toolController.getActiveTool();
        // @ts-expect-error TS(2339): Property 'toolId' does not exist on type 'never'.
        if (activeTool && activeTool?.toolId !== TOOLS_ID.NOTES_TOOL) return;

        this.selectedFeature = this.mapObj.map.forEachFeatureAtPixel(
            e.pixel,
            (_feature: $TSFixMe, _layer: $TSFixMe) => {
                if (_layer.get('id') === MAP_LAYERS.NOTES) {
                    return _feature;
                }
                return null;
            }
        );

        // User wants to add new note. Open add notes container.
        if (this.getIsAddingNote()) {
            this.addCoord = toLonLat(e.coordinate);

            // Close any note if it is open because we are going to add new note
            this.closeNote(false);
            this.addNotesContainer({ show: true });
            this.showContainerAt(e.originalEvent.pageX, e.originalEvent.pageY);

            // show the upload file container so that user can upload images to a new note
            const uploadImagesContainer = document.getElementById(this.domElements.uploadImagesContainer);
            // @ts-expect-error TS(2531): Object is possibly 'null'.
            uploadImagesContainer.style.display = 'flex';
        }
        // A feature is selected open view notes container
        else if (this.selectedFeature) {
            // empty the images container to avoid duplication if the user clicks the same note multiple times
            this.emptyImagesContainer();

            const noteData = this.selectedFeature.get('noteData');
            this.viewNotesContainer({ show: true, text: noteData.text, images: noteData.media_files });

            // hide the upload file container so that user cannot edit images
            const uploadImagesContainer = document.getElementById(this.domElements.uploadImagesContainer);
            // @ts-expect-error TS(2531): Object is possibly 'null'.
            uploadImagesContainer.style.display = 'none';
            this.showContainerAt(e.originalEvent.pageX, e.originalEvent.pageY);
        } else {
            this.closeNote();
        }
    };

    closeNote = (resetAdding = true) => {
        this.hideContainer();
        this.viewNotesContainer({ show: false });
        this.addNotesContainer({ show: false });
        this.selectedFeature = null;
        changeMapCursor(true, 'default');

        if (resetAdding) {
            this.addCoord = [];
            toolController.dispatch({ type: 'SET_ADDING_NEW_NOTE', payload: false });
            const activeTool = toolController.getActiveTool();
            // @ts-expect-error TS(2339): Property 'toolId' does not exist on type 'never'.
            if (activeTool?.toolId === TOOLS_ID.NOTES_TOOL) {
                toolController.updateActive(null);
            }
        }
    };

    editNote() {
        const noteData = this.selectedFeature.get('noteData');
        this.addNotesContainer({ show: true, text: noteData.text, unicode: noteData.icon_emoji_unicode });
    }

    setIsAdding = (isAdding: $TSFixMe) => {
        toolController.dispatch({ type: 'SET_ADDING_NEW_NOTE', payload: isAdding });
    };

    saveNote = (imagesToUpload: $TSFixMe, pinIconId: $TSFixMe, noteCreatorId = '', worksheetId = '') => {
        return new Promise((res, rej) => {
            const noteData = new FormData();

            // if the note alredy exists then we fetch its content
            if (this.selectedFeature) {
                const oldData = this.selectedFeature.get('noteData');
                noteData.append('id', oldData.id);
            } else {
                // images and note creator id are added only if it is a new note
                if (imagesToUpload.length) imagesToUpload.map((img: $TSFixMe) => noteData.append('files', img));
                if (noteCreatorId) noteData.append('user', noteCreatorId);
            }

            // @ts-expect-error TS(2339): Property 'value' does not exist on type 'HTMLEleme... Remove this comment to see the full error message
            const newText = document.getElementById(this.domElements.input)?.value || '';
            noteData.append('text', newText);

            if (pinIconId) noteData.append('icon_emoji_unicode', pinIconId);

            if (noteData.get('id')) {
                // Editing Note
                const url = interpolate(
                    this.isSharedView
                        ? this.mapObj.isBlueprintMap
                            ? BLUEPRINT_SHARED_REQUEST_NOTE
                            : SHARED_REQUEST_NOTE
                        : REQUEST_NOTE,
                    [this.requestId, noteData.get('id')]
                );

                const apiCall = this.isSharedView
                    ? openAPI(url, noteData, 'PATCH')
                    : patchAPI(url, {
                          data: noteData,
                          prefix: this.mapObj.isBlueprintMap ? BP_PREFIX : ''
                      });

                apiCall
                    .then(() => {
                        const oldNoteData = this.selectedFeature.get('noteData');
                        oldNoteData.text = newText;
                        if (pinIconId) oldNoteData.icon_emoji_unicode = pinIconId;
                        this.selectedFeature.set('noteData', { ...oldNoteData });
                        this.closeNote();
                        // @ts-expect-error TS(2794): Expected 1 arguments, but got 0. Did you forget to... Remove this comment to see the full error message
                        res();
                    })
                    .catch(err => rej(err));
            } else {
                noteData.append('lon', parseFloat(this.addCoord[0]).toFixed(15));
                noteData.append('lat', parseFloat(this.addCoord[1]).toFixed(15));
                if (this.mapObj.isBlueprintMap) {
                    noteData.append('worksheet_id', worksheetId);
                }
                const url = interpolate(
                    this.isSharedView
                        ? this.mapObj.isBlueprintMap
                            ? BLUEPRINT_SHARED_REQUEST_NOTES
                            : SHARED_REQUEST_NOTES
                        : REQUEST_NOTES,
                    [this.requestId]
                );

                const apiCall = this.isSharedView
                    ? openAPI(url, noteData, 'POST')
                    : postAPI(url, {
                          data: noteData,
                          prefix: this.mapObj.isBlueprintMap ? BP_PREFIX : ''
                      });

                apiCall
                    .then(data => {
                        // @ts-expect-error TS(2571): Object is of type 'unknown'.
                        noteData.append('id', data.id);
                        this.closeNote();
                        this.addNote(data);
                        // @ts-expect-error TS(2794): Expected 1 arguments, but got 0. Did you forget to... Remove this comment to see the full error message
                        res();
                    })
                    .catch(err => rej(err));
            }
        });
    };

    deleteNote = () => {
        if (this.selectedFeature) {
            const noteData = this.selectedFeature.get('noteData');

            const deleteBtn = document.getElementById(this.domElements.deleteBtn);
            const deleteLoadingBtn = document.getElementById(this.domElements.deleteLoadingBtn);

            if (deleteBtn && deleteLoadingBtn) {
                deleteBtn.style.display = 'none';
                deleteLoadingBtn.style.display = 'inline-block';
            }

            const url = interpolate(
                this.isSharedView
                    ? this.mapObj.isBlueprintMap
                        ? BLUEPRINT_SHARED_REQUEST_NOTE
                        : SHARED_REQUEST_NOTE
                    : REQUEST_NOTE,
                [this.requestId, noteData.id]
            );

            const apiCall = this.isSharedView
                ? openAPI(url, {}, 'DELETE')
                : patchAPI(url, {
                      method: 'DELETE',
                      prefix: this.mapObj.isBlueprintMap ? BP_PREFIX : ''
                  });

            apiCall
                .then(() => {
                    this.notesLayer.getSource().removeFeature(this.selectedFeature);
                    this.closeNote();
                })

                .finally(() => {
                    if (deleteBtn && deleteLoadingBtn) {
                        deleteBtn.style.display = 'inline-block';
                        deleteLoadingBtn.style.display = 'none';
                    }
                });
        }
    };

    setNotesVisibility = (val: $TSFixMe) => {
        this.notesVisibility = val;
        outputMap.setVisibility(MAP_LAYERS.NOTES, val);
    };

    off() {
        this.mapObj.map.un('singleclick', this.onMapClick);
        this.closeNote();
    }

    emptyImagesContainer() {
        const viewImagesContainer = document.getElementById(this.domElements.viewImagesContainer);
        if (viewImagesContainer) viewImagesContainer.innerHTML = '';
    }
}

export default Notes;
