import Tile from 'ol/layer/Tile';
import XYZ from 'ol/source/XYZ';
import Circle from 'ol/geom/Circle';
import { buffer, containsXY } from 'ol/extent';
import { fromLonLat } from 'ol/proj';

import { editParcel } from './MapInit';
import { BUFFER } from '../../Constants/Constant';

class TileImagery {
    extent: $TSFixMe;

    lnglat: $TSFixMe;

    mapObj: $TSFixMe;

    selectedImagery: $TSFixMe;

    sourceType: $TSFixMe;

    tileLayer: $TSFixMe;

    tilesetUrl: $TSFixMe;

    constructor(mapObj: $TSFixMe) {
        this.mapObj = mapObj;
        this.tileLayer = null;
        this.sourceType = '';
        this.tilesetUrl = null;
        this.extent = null;
        this.lnglat = null;
    }

    on({ imagery, isManualReport }: $TSFixMe) {
        if (imagery) {
            // if imagery is different than reinitialised
            if (this.selectedImagery && this.selectedImagery.url !== imagery.url) this.off();

            this.selectedImagery = imagery;
        }
        let maxZoom = 19;
        if (!this.tileLayer && this.selectedImagery) {
            const _url = this.selectedImagery.url;

            if (!(this.selectedImagery.layer === 'nearmap' || _url.includes('bluesky-ultra'))) {
                // for others Google Imagery will be used
                return null;
            } else {
                maxZoom = 21;
            }
            this.sourceType = this.selectedImagery.layer;
            this.setTileUrl({ url: _url, maxZoom, isManualReport });
            // no pan restriction in draft state
        } else if (this.hasExtentUpdated()) {
            // tilelayer source is not changed but extent has been changed it happens when we draw or edit parcel.
            // So we have to update the extent accordingly
            this.updateExtent({ extent: editParcel.getParceExtent(), isManualReport });
        }
        return null;
    }

    setSelectedImagery({ imagery, isManualReport }: $TSFixMe) {
        if (!this.tileLayer) return this.on({ imagery, isManualReport });

        if (imagery && this.tileLayer && imagery !== this.selectedImagery) {
            this.sourceType = imagery.layer;
            this.setTileUrl({ url: imagery.url, isManualReport });
        }
        this.selectedImagery = imagery;
        return null;
    }

    outputImageryOn(url: $TSFixMe) {
        this.tilesetUrl = url;
        if (!this.tileLayer) {
            this.setTileUrl({ url });
            this.addPanRestriction();
        }
    }

    setTileUrl({ url, maxZoom, isManualReport }: $TSFixMe) {
        let isVisible = true;
        if (this.tileLayer) {
            isVisible = this.tileLayer.getVisible();
            this.mapObj.removeLayer(this.tileLayer);
        }

        const source = new XYZ({ url, maxZoom, crossOrigin: 'anonymous' });
        this.tileLayer = new Tile({ source, visible: isVisible });
        this.mapObj.addLayer(this.tileLayer);
        this.updateExtent({ extent: editParcel.getParceExtent(), isManualReport });
    }

    setLngLat(lnglat: $TSFixMe, isManualReport: $TSFixMe) {
        this.lnglat = lnglat;
        if (this.tileLayer && this.selectedImagery) {
            this.updateExtent({ extent: editParcel.getParceExtent(), isManualReport });
        }
    }

    hasExtentUpdated() {
        const parcelExtent = editParcel.getParceExtent();
        if (parcelExtent && parcelExtent[0] !== Infinity && this.extent) {
            return JSON.stringify(parcelExtent) !== JSON.stringify(this.extent);
        }
        return null;
    }

    updateExtent({ extent, isManualReport }: $TSFixMe) {
        const tile_buffer = isManualReport ? BUFFER.MANUAL_MEASUREMENT_TILESERVER : BUFFER.TILESERVER;
        if (extent && extent[0] !== Infinity) {
            // We have lot boundary we can add a buffer of 0.1KM(Manual Measurement) and 0.75 KM(for others) on each side and pass this as tile layer extent
            this.extent = buffer(extent, tile_buffer);
        } else if (this.lnglat && this.lnglat.length > 0) {
            // We don't have a Parcel. So, We will create a circle around the properties center with radius as 0.1KM(Manual Measurement) and 0.75 KM(for others) and take it's extent
            const center = fromLonLat(this.lnglat);
            const circleGeometry = new Circle(center, tile_buffer);
            this.extent = circleGeometry.getExtent();
        }

        if (this.tileLayer && this.extent) return this.tileLayer.setExtent(this.extent);
        return null;
    }

    showTileImagery = (show: $TSFixMe) => {
        if (this.tileLayer) this.tileLayer.setVisible(show);
    };

    addPanRestriction() {
        this.mapObj.map.on('moveend', this.restrictFn);
    }

    restrictFn = () => {
        const parcelExtent = editParcel.getParceExtent();
        if (!parcelExtent?.length) return;

        const bufferValue = this.sourceType === 'nearmap' ? BUFFER.NEARMAP : BUFFER.GIC;
        const bufferedExtent = buffer(parcelExtent, bufferValue);

        const view = this.mapObj.map.getView();
        const center = view.getCenter();
        if (!containsXY(bufferedExtent, center[0], center[1])) {
            this.mapObj.zoomToExtent(parcelExtent);
        }
    };

    removePanRestriction() {
        this.mapObj.map.un('moveend', this.restrictFn);
    }

    isTileLayerAvailable() {
        if (this.tileLayer) return true;
        return false;
    }

    off(removeLngLat = true) {
        this.mapObj.removeLayer(this.tileLayer);
        this.removePanRestriction();
        this.tileLayer = null;
        this.sourceType = '';
        this.tilesetUrl = null;
        this.extent = null;
        if (removeLngLat) {
            this.lnglat = null;
        }
        this.selectedImagery = null;
    }
}

export default TileImagery;
