import { captureException } from '@sentry/react';
import message from 'antd/es/message';

import { TOOLBAR_MAP, TOOLS } from './ToolbarMap';
import { History, layerTracker, mapObj, outputMap } from '../../OlMap/MapInit';
import { AI_EVENT_TYPE, REQUEST_STATUS_ENUM, TOOLS_ID } from '../../../Constants/Constant';
import { gaMetrics, getOutputData, hasDependencyOnSelectedFeatures } from '../../../Utils/HelperFunctions';
import { CANNOT_ADD_NEARBY, PARCEL_NOT_PRESENT } from '../../../Constants/Messages';
import { Observer } from '../../../Utils/Observer';
import { useRequest } from '../../../Stores/Request';

// The purpose of using Object.freeze() is to prevent the EventType object from being modified in any way.
// This means that properties cannot be added, removed, or modified, and any attempts to do so will result in an
// error being thrown.
export const TOOL_EVENT = Object.freeze({
    SCALE_LINE_ADDED: 'SCALE_LINE_ADDED',
    REMOVE_SCALE_LINE: 'REMOVE_SCALE_LINE',
    FEATURE_ADDED: 'FEATURE_ADDED',
    EDIT_LAYER: 'EDIT_LAYER',
    DRAW_HOLE: 'DRAW_HOLE',
    SPLIT_FEATURE: 'SPLIT_FEATURE',
    LASSO_TOOL: 'LASSO_TOOL',
    LASSO_TOOL_GEOMETRY: 'LASSO_TOOL_GEOMETRY',
    LABEL_TOOL: 'LABEL_TOOL',
    RECLASSIFY_TOOL: 'RECLASSIFY_TOOL',
    DELETE_FEATURE: 'DELETE_FEATURE',
    ARROW_TOOL: 'ARROW_TOOL',
    ICON_TOOL: 'ICON_TOOL',
    EDIT_PARCEL: 'EDIT_PARCEL',
    PARCEL_SAVE: 'PARCEL_SAVE',
    NEARBY_PARCEL: 'NEARBY_PARCEL',
    LABEL_UPDATED: 'LABEL_UPDATED',
    CUSTOM_FEATURE_ADDITION: 'CUSTOM_FEATURE_ADDITION',
    CUSTOM_FEATURE_DELETION: 'CUSTOM_FEATURE_DELETION',
    NUMERICAL_FEATURE_POPUP_TOGGLE: 'NUMERICAL_FEATURE_POPUP_TOGGLE',
    TAGS_SELECT_FEATURES: 'TAGS_SELECT_FEATURES',
    CLEAR_TAGS_SELECTION: 'CLEAR_TAGS_SELECTION',
    SELECT_FEATURES: 'SELECT_FEATURES',
    SELECT_LAYERS: 'SELECT_LAYERS',
    ROTATE_TOOL: 'ROTATE_TOOL',
    FLIP_TOOL: 'FLIP_TOOL',
    MOVE_FEATURE: 'MOVE_FEATURE',
    LOAD_CLIPBOARD: 'LOAD_CLIPBOARD',
    PASTE_FEATURES: 'PASTE_FEATURES',
    EMPTY_STACK: 'EMPTY_STACK',
    MEASURE_TOOL: 'MEASURE_TOOL',
    ZONE_DIVISION_TOOL: 'ZONE_MEASUREMENT_TOOL',
    ASSIGN_TAGS: 'ASSIGN_TAGS',
    ZONE_MERGE_TOOL: 'ZONE MERGE TOOL',
    ADD_CALLOUT_VALUE: 'ADD_CALLOUT_VALUE',
    UPDATE_LABEL_BOX: 'UPDATE_LABEL_BOX'
});

class ToolController extends EventTarget {
    mapObj: $TSFixMe;
    // Initialize with tools that are not available in toolbar for example 'Notes' tool

    instance = {};

    constructor(_mapObj: $TSFixMe) {
        super();
        this.mapObj = _mapObj;
        this._observeEvents = this._observeEvents.bind(this);
    }

    _observeEvents = (event: $TSFixMe, data: $TSFixMe) => {
        this.dispatchEvent(new CustomEvent('tool-event', { detail: { type: event, data } }));
    };

    sendEvent(toolId: $TSFixMe, event: $TSFixMe, data: $TSFixMe) {
        const toolInstance = this.getToolInstance(toolId);

        // If toolInstance couldn't be found, or
        // it doesn't have the method to handle new events, then return.
        if (!toolInstance || !toolInstance.newEvent || typeof toolInstance.newEvent !== 'function') return;

        toolInstance.newEvent(event, data);
    }

    setActive(toolId: $TSFixMe, zoneId = null) {
        try {
            this.handleToolClick({ id: toolId, zoneId });
            this.updateActive({ toolId });
        } catch (error) {
            captureException(error);
        }
    }

    toolOn(toolId: $TSFixMe, options: $TSFixMe) {
        const toolInstance = this.getToolInstance(toolId);
        if (!toolInstance) return;
        toolInstance.on(options);
    }

    toolOff(toolId: $TSFixMe) {
        const toolInstance = this.getToolInstance(toolId);
        if (!toolInstance) return;
        toolInstance.off();
    }

    updateActive(active: $TSFixMe) {
        // @ts-expect-error TS(2339): Property 'activeTool' does not exist on type 'Wind... Remove this comment to see the full error message
        if (!active) window.activeTool = undefined;
        this.dispatch({ type: 'SET_TOOL_ACTIVE', payload: active });
        // Reset sidepanel dropdown and activeFeature while switching between other tools
        this.dispatch({ type: 'ACTIVE_FEATURE', payload: null });
    }

    getActiveTool() {
        return useRequest.getState()?.toolbar?.active;
    }

    dispatch(action: $TSFixMe) {
        const dispatchAction = useRequest.getState()?.dispatch;
        dispatchAction(action);
    }

    handleToolClick({ id, shortcutKey = false, zoneId = null }: $TSFixMe) {
        try {
            const tool = TOOLS[id];
            if (!tool) throw new Error(`No tool found with the given id:${id}`);
            const { requestData, highlightedLayers } = useRequest.getState();

            if (highlightedLayers.length) {
                this.dispatch({ type: 'SET_HIGHLIGHTED_LAYERS', payload: [] });
                // @ts-expect-error
                const outputData = getOutputData()?.outputs;
                outputMap.restoreOutputLayers(outputData);
            }
            if (!hasDependencyOnSelectedFeatures(id) && id !== TOOLS_ID.PASTE_TOOL) {
                this.offTools({});
                // @ts-expect-error TS(2339): Property 'activeTool' does not exist on type 'Wind... Remove this comment to see the full error message
                window.activeTool = id;
                gaMetrics(tool.label);
            }
            const toolInstance = this.getToolInstance(id);

            // If the tool is observable then observe to its events
            if (toolInstance instanceof Observer) {
                if (!toolInstance.observers.length) {
                    toolInstance.addObserver(this._observeEvents);
                }
            }

            // No need to enable notes tool because it is always enabled.
            if (id === TOOLS_ID.NOTES_TOOL) {
                toolInstance.setIsAdding(true);
            } else {
                // hide zones whenever any other tool is selected
                if (!(id === TOOLS_ID.ZONE_DIVISION_TOOL || id === TOOLS_ID.ZONE_MERGE_TOOL)) {
                    outputMap.toggleAllZonesLayers(false);
                    this.dispatch({ type: 'ZONES_VISIBLE', payload: false });
                }
                toolInstance.on({
                    isDraw: id === TOOLS_ID.ADD_PARCEL,
                    // @ts-expect-error TS(2339): Property 'status' does not exist on type '{}'.
                    requestStatus: requestData.status,
                    // @ts-expect-error TS(2339): Property 'id' does not exist on type '{}'.
                    requestId: requestData.id,
                    toolId: id,
                    shortcutKey,
                    zoneId
                });
            }
        } catch (err) {
            // @ts-expect-error TS(2571): Object is of type 'unknown'.
            if (err?.message === 'PARCEL_NOT_FOUND') return message.error(PARCEL_NOT_PRESENT);
            // @ts-expect-error TS(2571): Object is of type 'unknown'.
            if (TOOLS_ID.NEARBY_PARCEL === id && err?.message === 'NO_SUPPORT_EDITED_PARCEL')
                return message.error(CANNOT_ADD_NEARBY);
            // @ts-expect-error TS(2571): Object is of type 'unknown'.
            if (TOOLS_ID.NEARBY_PARCEL === id && err?.message === 'NO_SUPPORT_MULTI_LOT')
                return message.error('Add Nearby properties can be used only if there is a single lot boundary');
            captureException(err);
        }
        return null;
    }

    getToolInstance(toolId: $TSFixMe) {
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        if (this.instance[toolId]) {
            // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            return this.instance[toolId];
        }

        // for some tools we're using same Class. So, to avoid creating multiple instances of same class, we're checking
        if ([TOOLS_ID.ADD_RECTANGLE, TOOLS_ID.ADD_CIRCLE, TOOLS_ID.FREE_HAND, TOOLS_ID.ADD_CURVE].includes(toolId)) {
            // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            if (this.instance[TOOLS_ID.ADD_FEATURE]) return this.instance[TOOLS_ID.ADD_FEATURE];
        }
        if (toolId === TOOLS_ID.ROTATE_COUNTER_CLOCKWISE) {
            // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            if (this.instance[TOOLS_ID.ROTATE_CLOCKWISE]) return this.instance[TOOLS_ID.ROTATE_CLOCKWISE];
        }

        if (toolId === TOOLS_ID.FLIP_VERTICAL) {
            // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            if (this.instance[TOOLS_ID.FLIP_HORIZONTAL]) return this.instance[TOOLS_ID.FLIP_HORIZONTAL];
        }

        if (toolId === TOOLS_ID.COPY_TOOL) {
            // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            if (this.instance[TOOLS_ID.CUT_TOOL]) return this.instance[TOOLS_ID.CUT_TOOL];
        }
        if (toolId === TOOLS_ID.TAG_TOOL) {
            // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            if (this.instance[TOOLS_ID.SELECT_TOOL]) return this.instance[TOOLS_ID.SELECT_TOOL];
        }

        try {
            const tool = TOOLS[toolId];

            // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            this.instance[toolId] = new tool.class(mapObj);

            // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            return this.instance[toolId];
        } catch (error) {
            captureException(error);
            return null;
        }
    }

    loadIcons(icons: $TSFixMe, refresh = false) {
        const uploadIcon = this.getToolInstance(TOOLS_ID.UPLOAD_ICON);
        if (!uploadIcon) return;
        if (refresh) uploadIcon.shouldLoadIcons = true;
        uploadIcon.cache.clear();
        uploadIcon.addIcons(icons);
    }

    loadArrows(arrows: $TSFixMe) {
        const arrowTool = this.getToolInstance(TOOLS_ID.ADD_ARROW);
        if (arrowTool) arrowTool.loadArrows(arrows);
    }

    loadMeasurements(measurements: $TSFixMe) {
        const measureTool = this.getToolInstance(TOOLS_ID.MEASURE_TOOL);
        if (measureTool) measureTool.addMeasurements(measurements);
    }

    loadLabels(labels: $TSFixMe) {
        const labelObj = this.getToolInstance(TOOLS_ID.LABEL);
        if (labelObj) labelObj.addLabels(labels);
    }

    loadLabelBoxes(labelBoxes: $TSFixMe) {
        const labelObj = this.getToolInstance(TOOLS_ID.LABEL_BOX);
        if (labelObj) {
            labelObj.addLabelBoxes(labelBoxes);
            outputMap.updateOverlays();
        }
    }

    loadNotes({ requestId, worksheetId }: $TSFixMe) {
        const notesTool = this.getToolInstance(TOOLS_ID.NOTES_TOOL);
        if (notesTool) notesTool.loadNotes({ requestId, worksheetId });
    }

    // set reset false for tools if they are not showing Undo Redo
    onToolCancel(reset: $TSFixMe) {
        // @ts-expect-error TS(2339): Property 'status' does not exist on type '{}'.
        const requestStatus = useRequest.getState()?.requestData?.status;

        const active = this.getActiveTool();

        // @ts-expect-error TS(2339): Property 'toolId' does not exist on type 'never'.
        if (requestStatus === REQUEST_STATUS_ENUM.COMPLETED && active?.toolId === TOOLS_ID.EDIT_PARCEL) {
            outputMap.toggleLayers(true);
        }
        this.updateActive(null);
        this.offTools({ reset });
        const container = document.getElementById('label-container');
        if (container) {
            container.style.display = 'none';
        }
    }

    offTools({ reset = true }) {
        try {
            this.dispatch({ type: 'ZONES_VISIBLE', payload: true });
            outputMap.toggleAllZonesLayers(true);
            const { isParcel } = History;

            TOOLBAR_MAP.forEach(toolId => {
                const toolInstance = this.getToolInstance(toolId);
                if (toolInstance) {
                    if (toolId === TOOLS_ID.NOTES_TOOL) {
                        toolInstance.setIsAdding(false);
                    } else {
                        toolInstance.off();
                    }

                    // If the tool is observable then stop observing (if we were).
                    if (toolInstance instanceof Observer) {
                        toolInstance.removeObserver(this._observeEvents);
                    }
                }
            });

            // @ts-expect-error TS(2339): Property 'activeTool' does not exist on type 'Wind... Remove this comment to see the full error message
            window.activeTool = undefined;
            if (reset) {
                // tool's off method set isParcel value to default value, which do not reset the parcel
                // so getting and setting isParcel value before and after off method is called respectively
                History.setIsParcel(isParcel);
                History.reset({ eventType: AI_EVENT_TYPE.TOOL_OFF }); // clear stack of undo/redo array
                History.setIsParcel(false);
            }
            // Clear all tracking layers
            layerTracker.clear();
        } catch (error) {
            // @ts-expect-error TS(2345): Argument of type '"oftools operation could not be ... Remove this comment to see the full error message
            captureException(error, 'oftools operation could not be completed');
        }
    }

    triggerEvent = (eventName: $TSFixMe, data: $TSFixMe) => {
        document.getElementById('toolbar')?.dispatchEvent(new CustomEvent(eventName, { bubbles: false, detail: data }));
    };

    setLoading = (isLoading: $TSFixMe) => {
        const active = this.getActiveTool();
        if (!active) return;
        // @ts-expect-error TS(2698): Spread types may only be created from object types... Remove this comment to see the full error message
        this.dispatch({ type: 'SET_TOOL_ACTIVE', payload: { ...active, loading: isLoading } });
    };

    setProgress = (isProgressActive: $TSFixMe) => {
        const active = this.getActiveTool();
        if (!active) return;
        // @ts-expect-error TS(2698): Spread types may only be created from object types... Remove this comment to see the full error message
        this.dispatch({ type: 'SET_TOOL_ACTIVE', payload: { ...active, progress: isProgressActive } });
    };
}

export default ToolController;
