// @ts-expect-error TS(7016): Could not find a declaration file for module 'ol-e... Remove this comment to see the full error message
import DrawHole from 'ol-ext/interaction/DrawHole';
import { HIGHLIGHT_STYLE, EDIT_STYLE } from '../MapBase';
import { MAP_LAYERS, REQUEST_STATUS_ENUM } from '../../../Constants/Constant';
import { History, editParcel, layerTracker } from '../MapInit';
import { Observer } from '../../../Utils/Observer';
import { TOOL_EVENT } from '../../Output/Toolbar/ToolController';

class DrawHoleTool extends Observer {
    hole: $TSFixMe;

    isDrawActive: $TSFixMe;

    isParcel: $TSFixMe;

    mapObj: $TSFixMe;

    parcelStyle: $TSFixMe;

    constructor(mapObj: $TSFixMe) {
        super();
        this.mapObj = mapObj;
        this.hole = null;
        this.isParcel = null;
        this.parcelStyle = null;
        this.isDrawActive = false;
    }

    on({ requestStatus }: $TSFixMe) {
        const isParcel = requestStatus === REQUEST_STATUS_ENUM.DRAFT;
        this.isParcel = isParcel;
        // show error msg when lot area is not present
        if (isParcel) {
            if (!editParcel.getFeatures()?.length) throw new Error('PARCEL_NOT_FOUND');
        }
        const interactionLayers: $TSFixMe = [];
        const layers = this.mapObj.map.getLayers();

        layers.forEach((layer: $TSFixMe) => {
            const layerName = layer.get('name');
            const isOutputLayer = layerName === MAP_LAYERS.OUTPUT;
            const isParcelLayer = layerName === MAP_LAYERS.PARCEL;

            if (isOutputLayer || (isParcel && isParcelLayer)) {
                interactionLayers.push(layer);
            }
        });

        if (isParcel) {
            const parcelLayer = this.mapObj.getParcelLayer(true);
            this.parcelStyle = parcelLayer.getStyle();
            parcelLayer.setStyle(EDIT_STYLE);
            History.setIsParcel(isParcel);
        }

        this.hole = new DrawHole({
            layers: interactionLayers,
            style: HIGHLIGHT_STYLE
        });

        this.mapObj.map.addInteraction(this.hole);
        this.hole.on('drawstart', () => {
            this.isDrawActive = true;
        });
        this.hole.on('drawend', this.handleChangedLayers);
        document.addEventListener('keydown', this.removeLastPoint);
    }

    handleChangedLayers = (e: $TSFixMe) => {
        this.isDrawActive = false;
        const layerId = e.feature.get('layerId');
        layerTracker.push(this.mapObj.getLayerName(layerId), layerId);

        if (!this.isParcel) {
            this.notifyObservers(TOOL_EVENT.DRAW_HOLE);
        } else {
            // @ts-expect-error TS(2554): Expected 1 arguments, but got 0.
            History.add();
        }
    };

    removeLastPoint = (event: $TSFixMe) => {
        if (event.stopPropagation) event.stopPropagation();

        const KeyID = event.keyCode;
        if (this.isDrawActive && (event.ctrlKey || event.metaKey) && KeyID === 90) {
            event.stopImmediatePropagation();
            this.hole.removeLastPoint();
        }
    };

    off() {
        if (this.hole) {
            const parcelLayer = this.mapObj.getParcelLayer(true);
            if (this.parcelStyle && parcelLayer) {
                parcelLayer.setStyle(this.parcelStyle);
            }
            this.mapObj.map.removeInteraction(this.hole);
        }
        this.isDrawActive = false;
    }
}

export default DrawHoleTool;
