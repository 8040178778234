import Feature from 'ol/Feature';
import Polygon, { fromExtent } from 'ol/geom/Polygon';
import { containsCoordinate } from 'ol/extent';
import { Observer } from '../../../Utils/Observer';
import { GEOMETRY_TYPE_STRING, MAP_LAYERS } from '../../../Constants/Constant';
import { TOOL_EVENT } from '../../Output/Toolbar/ToolController';
import { layerTracker } from '../MapInit';
import { changeMapCursor } from '../../../Utils/HelperFunctions';

class FillTool extends Observer {
    bpLotExtent: $TSFixMe;

    invalidSpace: $TSFixMe;

    layerId: $TSFixMe;

    mapObj: $TSFixMe;

    constructor(mapObj: $TSFixMe) {
        super();
        this.mapObj = mapObj;
        this.layerId = null;
        this.invalidSpace = false;
        this.bpLotExtent = null;
    }

    on({ layerId }: $TSFixMe) {
        this.off();
        if (!layerId) return;
        this.layerId = layerId;

        if (this.mapObj.isBlueprintMap) {
            const bpSheetExtent = this.mapObj.baseLayer.get('bp_page_extent');
            this.bpLotExtent = new Feature(fromExtent(bpSheetExtent));
        }

        this.mapObj.map.on('pointermove', this.changeToFillCursor);
        this.mapObj.map.on('singleclick', this.onMapClick);
    }

    onMapClick = (event: $TSFixMe) => {
        const clickCoordinate = event.coordinate;
        const layers = this.mapObj.map.getLayers();

        // Iterate through all layers with polygons
        layers?.forEach((layer: $TSFixMe) => {
            if (
                layer?.get('name') === MAP_LAYERS.OUTPUT &&
                layer?.getSource()?.getFeatures()[0]?.getGeometry()?.getType() === GEOMETRY_TYPE_STRING.POLYGON
            ) {
                layer?.getSource()?.forEachFeature((feature: $TSFixMe) => {
                    const featureCoordinates = feature.getGeometry().getCoordinates();
                    if (featureCoordinates.length <= 1) return;

                    const outerRingCoordinates = featureCoordinates[0];
                    const outerGeometry = new Polygon([outerRingCoordinates]);

                    // Check if the clicked point intersects with any feature
                    if (outerGeometry.intersectsCoordinate(clickCoordinate)) {
                        const geometry = feature.getGeometry();
                        const coordinates = geometry.getCoordinates();

                        // Check if clicked point is inside any hole of the feature
                        for (let i = 1; i < coordinates.length; i++) {
                            const holeCoordinates = coordinates[i];
                            const holeGeometry = new Polygon([holeCoordinates]);
                            const holeFeature = new Feature({
                                geometry: holeGeometry
                            });

                            if (holeGeometry.intersectsCoordinate(clickCoordinate)) {
                                const clickedFeature = this.mapObj.map.forEachFeatureAtPixel(
                                    event.pixel,
                                    (f: $TSFixMe) => {
                                        if (f && this.featureExistsInsideHole(holeFeature, f)) {
                                            return f;
                                        }
                                        return null;
                                    }
                                );

                                if (clickedFeature) {
                                    if (clickedFeature.get('layerId') === this.layerId) return;

                                    const featureLayer = this.mapObj.getLayerById(clickedFeature.get('layerId'));
                                    const targetLayer = this.mapObj.getLayerById(this.layerId);

                                    if (targetLayer && featureLayer) {
                                        const targetLayerId = this.layerId;
                                        const featureLayerId = clickedFeature.get('layerId');
                                        const style = targetLayer.getStyle();
                                        clickedFeature.setStyle(style);
                                        clickedFeature.set('layerId', targetLayerId);
                                        targetLayer.getSource().addFeature(clickedFeature);
                                        featureLayer.getSource().removeFeature(clickedFeature);
                                        layerTracker.push(targetLayer.get('name'), targetLayerId);
                                        layerTracker.push(featureLayer.get('name'), featureLayerId);
                                    }
                                } else {
                                    const layerNew = this.mapObj.getLayerById(this.layerId);
                                    const style = layerNew.getStyle();
                                    holeFeature.setStyle(style);
                                    holeFeature.set('layerId', this.layerId);
                                    const vectorSource = layerNew.getSource();
                                    vectorSource.addFeature(holeFeature);
                                    layerTracker.push(this.mapObj.getLayerName(this.layerId), this.layerId);
                                }

                                this.notifyObservers(TOOL_EVENT.FEATURE_ADDED);
                                break;
                            }
                        }
                    }
                });
            }
        });
    };

    featureExistsInsideHole = (holeFeature: $TSFixMe, feature: $TSFixMe) => {
        const extent = holeFeature.getGeometry().getExtent();
        const coords = feature.getGeometry().getCoordinates()[0];

        return coords.every((coord: $TSFixMe) => containsCoordinate(extent, coord));
    };

    changeToFillCursor = (e: $TSFixMe) => {
        this.invalidSpace = !this.mapObj.coordsExistsInParcel(
            e.coordinate,
            this.mapObj.isBlueprintMap ? this.bpLotExtent : undefined
        );
        changeMapCursor(
            this.invalidSpace,
            'not-allowed',
            'url(https://storage.googleapis.com/falcon-shared-images-front-end/assets/fill_tool_updated.png), auto'
        );
    };

    off() {
        this.mapObj.map.un('singleclick', this.onMapClick);
        this.mapObj.map.un('pointermove', this.changeToFillCursor);
        this.layerId = null;
    }
}

export default FillTool;
