/**
 * Provide History of the action performed on the map from tools.
 *
 */
import { captureException, setExtra } from '@sentry/minimal';
import GeoJSON from 'ol/format/GeoJSON';
import { filterBlacklistPropertiesInFeatures } from '../../Utils/olutils';
import { getRequestData } from '../../Utils/HelperFunctions';

class HistoryManager {
    customSetSource: $TSFixMe;

    index: $TSFixMe;

    isParcel: $TSFixMe;

    listeners: $TSFixMe;

    lock: $TSFixMe;

    mapObj: $TSFixMe;

    stack: $TSFixMe;

    constructor(mapObj: $TSFixMe) {
        this.listeners = [];
        this.stack = [];
        this.index = -1;
        this.isParcel = false;
        this.customSetSource = null;
        this.mapObj = mapObj;
        this.lock = false;
    }

    /* Add a layer to the queue. */
    add = (layers: $TSFixMe) => {
        try {
            if (!layers) {
                layers = this.getLayersChanges();
            }
            // For removing previous history
            this.stack.splice(this.index + 1);
            // Adding current map state
            const src = layers;
            const srcStr = JSON.stringify(src);
            const lastRecordStr = JSON.stringify(this.stack[this.index]);
            const isLastRecordSame = srcStr === lastRecordStr;
            if (src?.length && !isLastRecordSame) {
                this.pushLayerToStack(src);
                // @ts-expect-error TS(2554): Expected 1 arguments, but got 0.
                this.triggerDispatch();
            }
        } catch (err) {
            setExtra('undostack', JSON.stringify(this.stack));
            // @ts-expect-error TS(2339): Property 'id' does not exist on type '{}'.
            setExtra('Request ID', getRequestData().id);
            captureException(err);
        }
    };

    getLayersChanges = () => {
        const layers = this.mapObj.getEditableLayers();
        const outputs: $TSFixMe = [];
        layers?.forEach((layer: $TSFixMe) => {
            const source = layer.getSource();
            if (source) {
                let features = layer.getSource().getFeatures();
                if (layer.get('blackListProperties')) {
                    features = filterBlacklistPropertiesInFeatures(features, layer.get('blackListProperties'));
                }

                const layerGeojson = new GeoJSON().writeFeatures(features);

                const dict = {
                    id: layer.get('id'),
                    geojson: JSON.parse(layerGeojson)
                };
                outputs.push(dict);
            }
        });
        return outputs;
    };

    pushLayerToStack = (mapLayers: $TSFixMe) => {
        this.stack.push(mapLayers);
        this.index = this.stack.length - 1;
    };

    setSource = (_layers: $TSFixMe) => {
        if (typeof this.customSetSource === 'function') return this.customSetSource(_layers);

        if (_layers?.length) {
            _layers.forEach((layer: $TSFixMe) => {
                const prevLayer = this.mapObj.getLayerById(layer.id);
                if (!prevLayer) return;

                prevLayer.getSource().clear();
                // @ts-expect-error TS(2559): Type 'false' has no properties in common with type... Remove this comment to see the full error message
                prevLayer.getSource().addFeatures(new GeoJSON().readFeatures(layer.geojson, false));
            });
        }
        return null;
    };

    undo = () => {
        if (this.lock) return null;
        if (this.index === 0) this.reset({ clearStack: false });
        const output = this.stack[this.index - 1];
        if (!output) return this;

        this.setSource(output);
        this.index--;
        // @ts-expect-error TS(2554): Expected 1 arguments, but got 0.
        this.triggerDispatch();
        return this;
    };

    redo = () => {
        if (this.lock) return null;
        const output = this.stack[this.index + 1];
        if (!output) return this;

        this.setSource(output);

        this.index++;
        // @ts-expect-error TS(2554): Expected 1 arguments, but got 0.
        this.triggerDispatch();
        return this;
    };

    refresh = () => {
        if (this.lock) return null;
        const output = this.stack[this.index];
        if (!output) return this.reset({ clearStack: false });

        this.setSource(output);
        // @ts-expect-error TS(2554): Expected 1 arguments, but got 0.
        this.triggerDispatch();
        return this;
    };

    //clears prev state on tool cancel
    reset = ({ clearStack = true, eventType }: $TSFixMe = {}) => {
        if (this.lock) return;
        const prev_size = this.stack.length;
        this.index = -1;
        if (clearStack) this.stack = [];

        if (prev_size > 0) {
            this.triggerDispatch({ action: 'reset', lastStackSize: prev_size, eventType });
        }
    };

    hasUndo = () => {
        return this.index > -1;
    };

    hasRedo = () => {
        return this.index < this.stack.length - 1;
    };

    addListener = (callback: $TSFixMe) => {
        return this.listeners.push(callback) - 1;
    };

    removeListener = (callbackId: $TSFixMe) => {
        delete this.listeners[callbackId];
    };

    triggerDispatch = (params: $TSFixMe) => {
        if (this.listeners?.length) {
            this.listeners.forEach((callback: $TSFixMe) => callback(params));
        }
    };

    setIsParcel = (flag: $TSFixMe) => {
        this.isParcel = flag;
    };

    setCustomSetSource = (func: $TSFixMe) => {
        this.customSetSource = func;
    };

    setLock = (lock: $TSFixMe) => {
        this.lock = lock;
        // @ts-expect-error TS(2554): Expected 1 arguments, but got 0.
        this.triggerDispatch();
    };
}

export default HistoryManager;
