import create from 'zustand';
import { devtools } from 'zustand/middleware';
import { constructTagsMap } from '../Components/tags/helpers';

const reducer = (state: $TSFixMe, { type, payload }: $TSFixMe) => {
    switch (type) {
        case 'SET_TAGS': {
            const tagsMap = constructTagsMap(payload);
            return { tags: payload, tagsMap };
        }
        default:
            return state;
    }
};

const store = (set: $TSFixMe) => ({
    tags: [],
    tagsMap: {},
    dispatch: (args: $TSFixMe) => set((state: $TSFixMe) => reducer(state, args))
});

export const useTags = create(devtools(store));
