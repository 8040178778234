import React, { lazy, Suspense, useEffect, memo, useCallback } from 'react';
import { Route, withRouter } from 'react-router-dom';
import shallow from 'zustand/shallow';
import { captureException } from '@sentry/react';

import { COMPANY_CONFIG } from './Constants/Urls';
import { SharedViewLoader } from './Loaders/SharedViewLoader';
import { storage } from './Stores/AppStore';
import { openAPI } from './Utils/ApiCalls';
import { SHARED_VIEW_PATHNAME } from './Constants/Constant';
import { detectUserOS, getParameterFromUrl } from './Utils/HelperFunctions';
import { isBeamUser, isRexelUser } from './Utils/usersTypes';
import { AttentiveLoader } from './Loaders/AttentiveLoader';
import { useCompanyConfig } from './Stores/CompanyConfig';
import { useUserDetails } from './Stores/UserDetails';
import { useDevice } from './Stores/Device';
import { useTitle } from './hooks/useTitle';
import './Styles/privateRoutes/style.less';
import './Styles/privateRoutes/automeasure.less';
import './Styles/privateRoutes/constructions.less';

const GoogleOAuthCallback = lazy(() => import('./LoginPage/GoogleOAuthCallback'));
const LinkedinOAuthCallback = lazy(() => import('./LoginPage/LinkedinOAuthCallback'));

const ThankYou = lazy(() => import('./LoginPage/ThankYou'));
const MainPage = lazy(() => import('./MainPage/MainPage'));
const Login = lazy(() => import('./LoginPage/Login'));

const Signup = lazy(() => import('./LoginPage/Signup'));
const ForgetPassword = lazy(() => import('./LoginPage/ForgetPassword'));
const ResetPassword = lazy(() => import('./LoginPage/ResetPassword'));
const SharedView = lazy(() => import('./SharedView/SharedView'));
const BlueprintSharedView = lazy(() => import('./SharedView/BlueprintSharedView'));
const BlueprintAccelerateDraft = lazy(() => import('./MainPage/Draft/BlueprintAccelerateDraft'));
const BlueprintAccelerateOutput = lazy(() => import('./MainPage/Output/BlueprintAccelerateOutput'));
const AccelerateCallback = lazy(() => import('./LoginPage/AccelerateCallback'));
const AccelerateLogout = lazy(() => import('./LoginPage/AccelerateLogout'));
const Logout = lazy(() => import('./LoginPage/Logout'));

// Routes that can be accessed by unauthorized users
const UNAUTH_PATHS = [
    '/login',
    '/signup',
    '/forget-password',
    '/reset-password',
    '/shared-view',
    '/blueprint/shared-view',
    '/oauth/google/callback',
    '/oauth/linkedin/callback',
    '/thankyou'
];
const ACCELERATE_PATH = ['/blueprint/accelerate/', '/auth/accelerate/'];

const SHARED_VIEW_ROUTES = {
    [SHARED_VIEW_PATHNAME.AERIAL]: SharedView,
    [SHARED_VIEW_PATHNAME.BLUEPRINT]: BlueprintSharedView
};

const setFavicon = (beamUser: $TSFixMe) => {
    const faviconLink = document.querySelector('link[rel="shortcut icon"]');

    const faviconUrl = beamUser ? '/favicon-beam.png' : '/favicon-automeasure.ico';

    if (faviconLink) {
        // @ts-expect-error TS(2339): Property 'href' does not exist on type 'Element'.
        faviconLink.href = faviconUrl;
    }
};

const App = (props: $TSFixMe) => {
    // Unauthenticated Routes
    // It maps a route to a component
    const ROUTES = [
        {
            path: '/login',
            component: Login
        },
        {
            path: '/signup',
            component: Signup
        },
        {
            path: '/forget-password',
            component: ForgetPassword
        },
        {
            path: '/reset-password',
            component: ResetPassword
        },
        {
            path: '/oauth/google/callback',
            component: GoogleOAuthCallback
        },
        {
            path: '/oauth/linkedin/callback',
            component: LinkedinOAuthCallback
        },
        {
            path: '/thankyou',
            component: ThankYou
        }
    ];

    const [afterLoginRouteStore, isLoggedIn, dispatchUserDetails] = useUserDetails(
        state => [state.afterLoginRoute, state.isLoggedIn, state.dispatch],
        shallow
    );
    const [dispatchDeviceInfo] = useDevice(state => [state.dispatch], shallow);
    const [dispatchCompanyConfig] = useCompanyConfig(state => [state.dispatch], shallow);

    // Set title of the page based on URL
    // @ts-expect-error TS(2554): Expected 1 arguments, but got 0.
    useTitle();

    const {
        location: { pathname }
    } = props;
    const isAcceleratePath = pathname.startsWith(ACCELERATE_PATH[0]) || pathname.startsWith(ACCELERATE_PATH[1]);
    const rexelUser = isRexelUser();

    if (rexelUser) {
        UNAUTH_PATHS.push(...['/logout', '/login/attentive']);
        ROUTES.push(
            ...[
                {
                    path: '/login/attentive',
                    component: Login
                },
                {
                    path: '/logout',
                    component: Logout
                }
            ]
        );
    }

    const beamUser = isBeamUser();

    useEffect(() => {
        setFavicon(beamUser);
    }, [beamUser]);

    // DO NOT MOVE THIS CODE BLOCK FROM HERE [ORDER MATTERS]
    // render SharedViewComponent if current path matches /shared-view or '/construction/shared-view'
    if (Object.prototype.hasOwnProperty.call(SHARED_VIEW_ROUTES, pathname)) {
        const SharedViewComponent = SHARED_VIEW_ROUTES[pathname];
        return (
            <Suspense fallback={<SharedViewLoader init />}>
                <SharedViewComponent />
            </Suspense>
        );
    }

    // It saves a post login route in the store
    const setAfterLoginRoute = useCallback(route => {
        dispatchUserDetails({ type: 'SET_AFTER_LOGIN_ROUTE', payload: route });
    }, []);

    // This will run for rexel users only
    useEffect(() => {
        if (rexelUser && !isLoggedIn) {
            const id = getParameterFromUrl('id');
            const token = getParameterFromUrl('token');
            const email = getParameterFromUrl('email');

            if (id && token && email) {
                const user = {
                    id,
                    token,
                    email
                };
                dispatchUserDetails({
                    type: 'SET_LOGIN',
                    payload: { isLoggedIn: true, user }
                });
            }
        }
    }, [rexelUser, isLoggedIn]);

    useEffect(() => {
        const isProdPostHogInitialised = process.env.APP_ENV === 'prod' && (window as any).posthog?.__loaded === true;

        const relativePathname =
            props?.location.pathname.slice(-1) === '/'
                ? props?.location.pathname.slice(0, -1)
                : props?.location?.pathname;

        if (isLoggedIn) {
            // Identify user on posthog
            if (isProdPostHogInitialised)
                // @ts-expect-error TS(2339): Property 'posthog' does not exist on type 'Window ... Remove this comment to see the full error message
                window.posthog.identify(storage.get('userId'), { email: storage.get('emailId') });

            // User is currently logged in but is trying to access one of the unauth path
            if (UNAUTH_PATHS.indexOf(relativePathname) > -1) {
                const afterLoginRoute = afterLoginRouteStore || '/';

                // Redirect user to afterLoginRoute or '/'
                // @ts-expect-error TS(2339): Property 'isEmbed' does not exist on type 'Window ... Remove this comment to see the full error message
                if (!window.isEmbed) {
                    props?.history.push(afterLoginRoute);
                }

                // Clear afterLoginRoute once user is redirected
                if (afterLoginRoute !== '/') {
                    setAfterLoginRoute('/');
                }
            }
        } else if (UNAUTH_PATHS.indexOf(relativePathname) === -1 && !isAcceleratePath) {
            // User is not logged in but is trying to access a route which requires authentication
            // Reset posthog on logout
            // @ts-expect-error TS(2339): Property 'posthog' does not exist on type 'Window ... Remove this comment to see the full error message
            if (isProdPostHogInitialised) window.posthog.reset();

            const path = props?.location?.pathname || '';
            const search = props?.location?.search || '';
            const params = new URLSearchParams(search);

            const action = params.get('action');
            const actionAvailable = action === 'update-card' || action === 'blueprint-request';
            if (actionAvailable) {
                // Set afterLoginRoute (user will be redirected to it after login)
                setAfterLoginRoute(`${path + search}`);
            }

            props?.history.push('/login');
        }
        // @ts-expect-error TS(2339): Property 'posthog' does not exist on type 'Window ... Remove this comment to see the full error message
        // eslint-disable-next-line no-underscore-dangle
    }, [isLoggedIn, window.posthog?.__loaded]); // It will re-run when login status changes for example on logout

    // Fetch and set company configuration data such as the logo
    // For example, BV users see their own logo instead of Attentive's logo.
    useEffect(() => {
        dispatchDeviceInfo({ type: 'SET_USER_OS', payload: detectUserOS() });

        openAPI(COMPANY_CONFIG, { domain: window.location.hostname })
            .then(res => {
                dispatchCompanyConfig({ type: 'COMPANY_CONFIG', payload: res || {} });
            })
            .catch(err => {
                captureException(err);
            })
            .finally(() => {
                dispatchUserDetails({ type: 'SET_IS_LOADING_LOGO', payload: false });
            });
    }, []);

    /**
     * User Authenticated: render MainPage if user is currently logged in
     * User Unauthenticated: one of the unprotected route can be rendered
     */
    let content;
    if (isAcceleratePath) {
        content = (
            <>
                <Route exact path='/auth/accelerate/callback' component={AccelerateCallback} />
                <Route exact path='/auth/accelerate/logout' component={AccelerateLogout} />
                <Route
                    exact
                    path='/blueprint/accelerate/request/:id'
                    render={(_props: $TSFixMe) => <BlueprintAccelerateOutput {..._props} />}
                />
                <Route
                    exact
                    path='/blueprint/accelerate/draft/request/:id'
                    render={(_props: $TSFixMe) => <BlueprintAccelerateDraft {..._props} />}
                />
            </>
        );
    } else if (isLoggedIn) {
        content = <MainPage />;
    } else {
        content = ROUTES.map(_route => {
            return (
                <Route
                    key={_route.path}
                    exact
                    path={_route.path}
                    render={(_props: $TSFixMe) => <_route.component {..._props} />}
                />
            );
        });
    }

    return <Suspense fallback={<AttentiveLoader />}>{content}</Suspense>;
};
export default withRouter(memo(App));
