export class Observer {
    observers: $TSFixMe;

    constructor() {
        this.observers = [];
    }

    addObserver(observer: $TSFixMe) {
        this.observers.push(observer);
    }

    removeObserver(observer: $TSFixMe) {
        const index = this.observers.indexOf(observer);
        if (index > -1) {
            this.observers.splice(index, 1);
        }
    }

    notifyObservers(eventType: $TSFixMe, data = null) {
        this.observers.forEach((observer: $TSFixMe) => {
            observer(eventType, data);
        });
    }
}
