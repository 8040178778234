import React from 'react';

import Button from 'antd/es/button';

const ErrorBoundary = () => {
    const onReload = () => {
        window.location.reload();
    };

    return (
        <div className='flex align-center justify-center h-100 flex-d-col text-center gap-2 p-4'>
            <img
                src='https://storage.googleapis.com/falcon-shared-images-front-end/assets/error_boundary%402x.png'
                alt='error'
                width={200}
            />
            <h1>An error has occurred</h1>
            <Button type='primary' size='small' onClick={onReload}>
                Reload
            </Button>
            <p className='error-boundary-text'>
                If the issue persists, please contact support by reaching out via email on{' '}
                <a href='mailto:support@attentive.ai'>support@attentive.ai</a> or via phone on +1 407 955 5627
            </p>
        </div>
    );
};

export default ErrorBoundary;
