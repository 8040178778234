import { captureException } from '@sentry/react';

import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import Draw from 'ol/interaction/Draw';
import GeoJSON from 'ol/format/GeoJSON';
import { getGeom } from '@turf/invariant';
import lineIntersect from '@turf/line-intersect';
import { featureCollection, feature } from '@turf/helpers';
import lineSplit from '@turf/line-split';
import buffer from '@turf/buffer';
import difference from '@turf/difference';

import { GEOMETRY_TYPE_ENUM, GEOMETRY_TYPE_STRING, INCH_TO_KM, MAP_LAYERS } from '../../../Constants/Constant';
import { MODIFY_STYLE } from '../MapBase';
import { layerTracker } from '../MapInit';
import { TOOL_EVENT } from '../../Output/Toolbar/ToolController';
import { Observer } from '../../../Utils/Observer';

class SplitFeature extends Observer {
    draw: $TSFixMe;

    isDrawActive: $TSFixMe;

    lineLayer: $TSFixMe;

    mapObj: $TSFixMe;

    outputLayers: $TSFixMe;

    constructor(mapObj: $TSFixMe) {
        super();
        this.mapObj = mapObj;
        this.outputLayers = [];
        this.draw = null;
        this.lineLayer = null;
        this.isDrawActive = false;
    }

    on() {
        const layers = this.mapObj.map.getLayers();
        layers.forEach((layer: $TSFixMe) => {
            const geometryType = layer.getProperties()?.layerData?.feature?.geometry_type;
            const isVisible = layer.getVisible();

            const isLineOrPolygon =
                geometryType === GEOMETRY_TYPE_ENUM.POLYGON || geometryType === GEOMETRY_TYPE_ENUM.LINESTRING;

            if (isVisible && layer.get('name') === MAP_LAYERS.OUTPUT && isLineOrPolygon) {
                this.outputLayers.push(layer);
            }
        });
        const sourceDrawnLines = new VectorSource({ wrapX: false });
        this.lineLayer = new VectorLayer({
            source: sourceDrawnLines
        });
        this.mapObj.map.addLayer(this.lineLayer);

        this.draw = new Draw({
            source: sourceDrawnLines,
            type: 'LineString',
            style: MODIFY_STYLE
            // dragVertexDelay: 0 //uncomment to stop map panning by click and drag
        });

        this.mapObj.map.addInteraction(this.draw);
        this.draw.on('drawstart', () => {
            this.isDrawActive = true;
        });
        this.draw.on('drawend', this.drawEnd);
        document.addEventListener('keydown', this.removeLastPointOnBack);
    }

    removeLastPointOnBack = (event: $TSFixMe) => {
        if (event.stopPropagation) event.stopPropagation();

        const KeyID = event.keyCode;
        if (this.isDrawActive && (event.ctrlKey || event.metaKey) && KeyID === 90) {
            event.stopImmediatePropagation();
            this.draw.removeLastPoint();
        }
        if (KeyID === 27) {
            this.draw.abortDrawing();
        }
    };

    drawEnd = (e: $TSFixMe) => {
        this.isDrawActive = false;
        const FormatGeoJSON = new GeoJSON();
        const drawnGeoJSON = FormatGeoJSON.writeFeatureObject(e.feature, {
            dataProjection: 'EPSG:4326',
            featureProjection: 'EPSG:3857'
        });
        // @ts-expect-error TS(2345): Argument of type 'Feature<Geometry, GeoJsonPropert... Remove this comment to see the full error message
        const drawnGeometry = getGeom(drawnGeoJSON);
        // if drawing started from double click, split is not working
        // so removing the first two coordinates if equal
        if (
            // @ts-expect-error TS(2339): Property 'coordinates' does not exist on type 'Geo... Remove this comment to see the full error message
            drawnGeometry?.coordinates?.length > 2 &&
            // @ts-expect-error TS(2339): Property 'coordinates' does not exist on type 'Geo... Remove this comment to see the full error message
            JSON.stringify(drawnGeometry.coordinates[0]) === JSON.stringify(drawnGeometry.coordinates[1])
        ) {
            // @ts-expect-error TS(2339): Property 'coordinates' does not exist on type 'Geo... Remove this comment to see the full error message
            drawnGeometry.coordinates.splice(1, 1);
        }

        if (drawnGeometry.type === 'LineString') {
            this.outputLayers.forEach((layer: $TSFixMe) => {
                const layerSource = layer.getSource();

                layerSource.forEachFeature((feature: $TSFixMe) => {
                    const featureGeo = FormatGeoJSON.writeFeatureObject(feature, {
                        dataProjection: 'EPSG:4326',
                        featureProjection: 'EPSG:3857'
                    });
                    // @ts-expect-error TS(2345): Argument of type 'Feature<Geometry, GeoJsonPropert... Remove this comment to see the full error message
                    const featureGemoetry = getGeom(featureGeo);
                    try {
                        let splittedFeatures;

                        if (featureGemoetry.type === GEOMETRY_TYPE_STRING.POLYGON) {
                            splittedFeatures = this.polygonCut(featureGemoetry, drawnGeometry);
                        } else if (featureGemoetry.type === GEOMETRY_TYPE_STRING.LINESTRING) {
                            splittedFeatures = this.lineCut(featureGeo, drawnGeoJSON);
                        }

                        if (splittedFeatures != null) {
                            const features = FormatGeoJSON.readFeatures(splittedFeatures, {
                                dataProjection: 'EPSG:4326',
                                featureProjection: 'EPSG:3857'
                            });
                            features.forEach(feature => {
                                feature.set('layerId', layer.get('id'));
                            });
                            layerSource.addFeatures(features);
                            layerSource.removeFeature(feature);

                            // Push layer in tracker
                            const layerId = feature.get('layerId');
                            layerTracker.push(this.mapObj.getLayerName(layerId), layerId);
                        }
                    } catch (err) {
                        captureException(err);
                    }
                });
            });
        }
        this.mapObj.map.removeLayer(this.lineLayer);

        this.notifyObservers(TOOL_EVENT.SPLIT_FEATURE);
    };

    lineCut(targetLine: $TSFixMe, drawnLine: $TSFixMe) {
        const splitted = lineSplit(targetLine, drawnLine);
        if (splitted.features.length) {
            return splitted;
        }
        return targetLine;
    }

    polygonCut(polygon: $TSFixMe, line: $TSFixMe) {
        const SPLIT_LINE_WIDTH = 0.5 * INCH_TO_KM; // 0.5 inches in kilometer

        if ((polygon.type !== 'Polygon' && polygon.type !== 'MultiPolygon') || line.type !== 'LineString') {
            return null;
        }

        const intersectPoints = lineIntersect(polygon, line);
        if (intersectPoints.features.length === 0) {
            return null;
        }

        // linePolygon represents an extremely narrow polygon made using the line
        const linePolygon = buffer(line, SPLIT_LINE_WIDTH, { units: 'kilometers' });
        // @ts-expect-error TS(2345): Argument of type 'Feature<Polygon, Properties>' is... Remove this comment to see the full error message
        const splittedPolygon = difference(polygon, linePolygon);

        // @ts-expect-error TS(2531): Object is possibly 'null'.
        if (splittedPolygon.geometry.type === 'MultiPolygon') {
            // @ts-expect-error TS(2339): Property 'coordinates' does not exist on type 'Geo... Remove this comment to see the full error message
            const { coordinates } = getGeom(splittedPolygon);
            const cutFeatures = coordinates.map((coord: $TSFixMe) => feature({ type: 'Polygon', coordinates: coord }));
            return featureCollection(cutFeatures);
        }

        return splittedPolygon;
    }

    off() {
        this.mapObj.map.removeInteraction(this.draw);
        this.lineLayer && this.mapObj.map.removeLayer(this.lineLayer);
        this.outputLayers = [];
        document.removeEventListener('keydown', this.removeLastPointOnBack);
        this.isDrawActive = false;
    }
}

export default SplitFeature;
