import { captureException } from '@sentry/react';
import { v4 as uuid4 } from 'uuid';

import Style from 'ol/style/Style';
import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';
import Overlay from 'ol/Overlay';
import Draw, { createBox } from 'ol/interaction/Draw';
import { containsCoordinate, getBottomLeft, getTopRight } from 'ol/extent';
import { DragPan, DoubleClickZoom, MouseWheelZoom } from 'ol/interaction';

import { LABEL_ACTIONS, TOOLS_ID } from '../../../Constants/Constant';
import { changeMapCursor } from '../../../Utils/HelperFunctions';
import { TOOL_EVENT } from '../../Output/Toolbar/ToolController';
import { Observer } from '../../../Utils/Observer';
import { outputMap, toolController } from '../MapInit';

class LabelBox extends Observer {
    copiedLabelBoxData: $TSFixMe;

    copyLonlat: $TSFixMe;

    dblClickZoom: $TSFixMe;

    domElements: $TSFixMe;

    dragPan: $TSFixMe;

    draw: $TSFixMe;

    editedOverlay: $TSFixMe;

    isEdit: $TSFixMe;

    isToolActive: $TSFixMe;

    lastCoords: $TSFixMe;

    lonLat: $TSFixMe;

    map: $TSFixMe;

    mapObj: $TSFixMe;

    mouseWheelZoom: $TSFixMe;

    positionChanged: $TSFixMe;

    selectedOverlay: $TSFixMe;

    textareaHeightAdjusted: $TSFixMe;

    textareaWidthAdjusted: $TSFixMe;

    constructor(mapObj: $TSFixMe) {
        super();
        this.mapObj = mapObj;
        this.draw = null;
        this.domElements = null;
        this.lastCoords = [];
        this.textareaWidthAdjusted = null;
        this.textareaHeightAdjusted = null;
        this.lonLat = null;
        this.selectedOverlay = null;
        this.isEdit = false;
        this.isToolActive = false;
        this.map = document.getElementById('map');
        this.dragPan = null;
        this.editedOverlay = null;
        this.dblClickZoom = null;
        this.mouseWheelZoom = null;
        this.positionChanged = false;
        this.copiedLabelBoxData = null;
        this.copyLonlat = null;
    }

    on() {
        this.off();

        // @ts-expect-error TS(2339): Property 'activeTool' does not exist on type 'Wind... Remove this comment to see the full error message
        this.isToolActive = window.activeTool === TOOLS_ID.LABEL_BOX;
        if (!this.isToolActive) return;

        this.mapObj.map.on('singleclick', this.onMapClick);
        this.mapObj.map.on('pointermove', this.handlePointerMove);
        this.mapObj.map.on('pointerup', this.handlePointerUp);

        changeMapCursor(false, '', 'crosshair');

        this.mapObj.map.getInteractions().forEach((interaction: $TSFixMe) => {
            if (interaction instanceof DragPan) {
                this.dragPan = interaction;
            } else if (interaction instanceof DoubleClickZoom) {
                this.dblClickZoom = interaction;
            } else if (interaction instanceof MouseWheelZoom) {
                this.mouseWheelZoom = interaction;
            }
        });
        this.mapObj.map.removeInteraction(this.dblClickZoom);

        this.draw = new Draw({
            type: 'Circle',
            geometryFunction: this.createBox,
            style: new Style({
                fill: new Fill({
                    color: 'rgba(255, 255, 255, 1)'
                }),
                stroke: new Stroke({
                    color: 'rgba(255, 255, 255, 1)',
                    width: 1
                })
            })
        });

        this.draw.on('drawend', this.handleDrawEnd);

        const overlays = [...this.mapObj.map.getOverlays().getArray()];
        if (overlays.length) {
            overlays.forEach(overlay => {
                if (overlay.get('labelBoxData')) {
                    this.mapObj.map.removeOverlay(overlay);
                    this.addWithListeners(overlay.get('labelBoxData'));
                }
            });
        }

        this.domElements = {
            container: document.getElementById('label-box-container'),
            textArea: document.getElementById('label-box-text-area'),
            textColor: document.getElementById('label-box-text-color'),
            textColorSelector: document.getElementById('label-box-text-color-selector'),
            textSize: document.getElementById('label-box-text-size'),
            textBoxColor: document.getElementById('label-box-bg-color'),
            textBoxColorSelector: document.getElementById('label-box-bg-selector'),
            deleteBtn: document.getElementById('label-box-delete'),
            copyBtn: document.getElementById('label-box-copy')
        };

        this.mapObj.map.addInteraction(this.draw);

        document.addEventListener('keydown', this.duplicateOverlay);
    }

    duplicateOverlay = (e: $TSFixMe) => {
        if (this.domElements.container.style.display === 'block') return;
        if (e.stopPropagation) e.stopPropagation();

        if ((e.ctrlKey || e.metaKey) && e.keyCode === 67) {
            e.stopImmediatePropagation();
            e.preventDefault();

            if (!this.selectedOverlay) return;
            this.copiedLabelBoxData = { ...this.selectedOverlay.get('labelBoxData') };
        }

        if ((e.ctrlKey || e.metaKey) && e.keyCode === 86) {
            e.stopImmediatePropagation();
            e.preventDefault();
            if (!this.copiedLabelBoxData) return;

            if (!this.copiedLabelBoxData) return;
            this.lonLat = null;
            this.textareaHeightAdjusted = null;
            this.textareaWidthAdjusted = null;

            const originalLabelBoxData = this.copiedLabelBoxData;
            const deltaLon = this.copyLonlat[0] - originalLabelBoxData.box_lonlat[0];
            const deltaLat = this.copyLonlat[1] - originalLabelBoxData.box_lonlat[1];

            const newExtent = [
                parseFloat(originalLabelBoxData.extent[0]) + deltaLon,
                parseFloat(originalLabelBoxData.extent[1]) + deltaLat,
                parseFloat(originalLabelBoxData.extent[2]) + deltaLon,
                parseFloat(originalLabelBoxData.extent[3]) + deltaLat
            ];

            const duplicatedLabelBoxData = {
                ...originalLabelBoxData,
                id: uuid4(),
                box_lonlat: this.copyLonlat,
                extent: newExtent,
                action: LABEL_ACTIONS.CREATED
            };

            this.addLabelBox(duplicatedLabelBoxData);
        }
    };

    onMapClick = (e: $TSFixMe) => {
        if (!this.lonLat) this.lonLat = e.coordinate;
        this.lastCoords = [e.originalEvent.pageX, e.originalEvent.pageY];
    };

    handleDrawEnd = (event: $TSFixMe) => {
        this.draw.setActive(false);
        this.dragPan.setActive(false);

        const extent = event.feature.getGeometry().getExtent();

        const bottomLeft = getBottomLeft(extent);
        const topRight = getTopRight(extent);
        const pixelBottomLeft = this.mapObj.map.getPixelFromCoordinate(bottomLeft);
        const pixelTopRight = this.mapObj.map.getPixelFromCoordinate(topRight);

        const boxCoordinates = this.mapObj.map.getPixelFromCoordinate(this.lonLat);
        const mapContainer = document.getElementById('map');
        // @ts-expect-error TS(2531): Object is possibly 'null'.
        const mapContainerRect = mapContainer.getBoundingClientRect();

        const resolution = this.mapObj.map.getView().getResolution();
        const scaleFactor = 1 / resolution;

        const width = pixelTopRight[0] - pixelBottomLeft[0];
        const height = pixelBottomLeft[1] - pixelTopRight[1];

        this.textareaWidthAdjusted = width + (width - width * scaleFactor) / scaleFactor;
        this.textareaHeightAdjusted = height + (height - height * scaleFactor) / scaleFactor;

        this.domElements.container.style.left = `${parseInt(mapContainerRect.left + boxCoordinates[0], 10)}px`;
        this.domElements.container.style.top = `${parseInt(mapContainerRect.top + boxCoordinates[1], 10)}px`;
        this.domElements.textArea.style.width = `${width}px`;
        this.domElements.textArea.style.height = `${height}px`;
        this.domElements.textArea.style.backgroundColor = '#FFFFFF';
        this.domElements.textArea.style.color = '#000000';
        this.domElements.textArea.style.fontSize = `${12}px`;
        this.domElements.textColor.value = '#000000';
        this.domElements.textColorSelector.style.backgroundColor = '#000000';
        this.domElements.textSize.value = 12;
        this.domElements.container.style.display = 'block';
        this.domElements.textBoxColor.value = '#FFFFFF';
        this.domElements.textBoxColorSelector.style.fill = '#000000';
        this.domElements.textArea.focus();
    };

    // @ts-expect-error TS(2554): Expected 3 arguments, but got 2.
    createBox = (coordinates: $TSFixMe, geometry: $TSFixMe) => createBox()(coordinates, geometry);

    addLabelBoxes(labelBoxes: $TSFixMe) {
        try {
            if (labelBoxes.length) {
                for (let i = 0; i < labelBoxes.length; i++) {
                    this.addLabelBox(labelBoxes[i]);
                }
            }
        } catch (err) {
            captureException(err);
        }
    }

    addLabelBox = (labelData: $TSFixMe) => {
        const divElement = document.createElement('div');

        divElement.classList.add('label-box-overlay');
        divElement.innerHTML = labelData.text;
        divElement.style.width = `${labelData?.width}px`;
        divElement.style.height = `${labelData?.height}px`;
        divElement.style.color = labelData.text_color;
        divElement.style.fontSize = `${labelData.text_size}px`;
        divElement.style.backgroundColor = labelData.box_color;

        const overlay = new Overlay({
            position: this.lonLat || labelData?.box_lonlat,
            element: divElement,
            stopEvent: false,
            // @ts-expect-error TS(2345): Argument of type '{ position: any; element: HTMLDi... Remove this comment to see the full error message
            dragging: false
        });

        const updatedLonLat = overlay.getPosition();
        const editedExtent = [
            // @ts-expect-error TS(2532): Object is possibly 'undefined'.
            updatedLonLat[0],
            // @ts-expect-error TS(2532): Object is possibly 'undefined'.
            updatedLonLat[1] - this.textareaHeightAdjusted,
            // @ts-expect-error TS(2532): Object is possibly 'undefined'.
            parseFloat(updatedLonLat[0]) + this.textareaWidthAdjusted,
            // @ts-expect-error TS(2532): Object is possibly 'undefined'.
            updatedLonLat[1]
        ];

        const data = {
            ...labelData,
            box_lonlat: this.lonLat || labelData?.box_lonlat,
            width: this.textareaWidthAdjusted || labelData?.width,
            height: this.textareaHeightAdjusted || labelData?.height,
            extent: this.textareaWidthAdjusted || this.textareaWidthAdjusted ? editedExtent : labelData?.extent
        };

        overlay.set('labelBoxData', data);
        this.mapObj.map.addOverlay(overlay);
        outputMap.updateOverlays();

        this.lonLat = null;
        this.selectedOverlay = null;
        toolController.dispatchEvent(new CustomEvent('label-box-text', { detail: '' }));
        if (this.editedOverlay) {
            this.domElements.deleteBtn.style.display = 'none';
            this.domElements.copyBtn.style.display = 'none';
            this.editedOverlay = null;
        }

        if (this.isToolActive) {
            if (Object.prototype.hasOwnProperty.call(data, 'action')) {
                this.notifyObservers(TOOL_EVENT.UPDATE_LABEL_BOX, data);
            }
            divElement.addEventListener('mouseenter', this.handleMouseEnter);
            divElement.addEventListener('mouseout', this.handleMouseOut);
            divElement.addEventListener('dblclick', this.editLabelBox);
            divElement.addEventListener('mousedown', this.handleMouseDown);

            changeMapCursor(false, '', 'crosshair');
            this.draw.setActive(true);
            this.dragPan.setActive(true);
        }
    };

    handleMouseEnter = (e: $TSFixMe) => {
        e.preventDefault();
        if (this.draw.getActive()) {
            this.draw.setActive(false);
            this.lonLat = null;
        }
        if (!(this.domElements.container.style.display === 'block')) {
            this.map.style.cursor = 'move';
        }
    };

    handleMouseOut = (e: $TSFixMe) => {
        e.preventDefault();
        if (!(this.domElements.container.style.display === 'block')) {
            this.draw.setActive(true);
            this.map.style.cursor = 'crosshair';
        }
    };

    handleMouseDown = (e: $TSFixMe) => {
        e.preventDefault();
        if (this.domElements.container.style.display === 'block' || !this.selectedOverlay) return;

        this.dragPan.setActive(false);
        this.selectedOverlay && this.selectedOverlay.set('dragging', true);
    };

    handlePointerMove = (evt: $TSFixMe) => {
        if (this.domElements.container.style.display === 'block') return;
        this.copyLonlat = evt.coordinate;
        evt.preventDefault();
        if (this.dragPan.getActive()) {
            const overlays = this.mapObj.map.getOverlays().getArray();
            overlays.find((overlay: $TSFixMe) => {
                if (overlay.get('labelBoxData')) {
                    const { extent } = overlay.get('labelBoxData');
                    if (containsCoordinate(extent, evt.coordinate)) {
                        this.selectedOverlay = overlay;
                        return overlay;
                    }
                }
                return null;
            });
        }
        if (this.selectedOverlay && this.selectedOverlay.get('dragging') === true) {
            this.selectedOverlay.setPosition(evt.coordinate);
            this.positionChanged = true;
        }
    };

    handlePointerUp = (evt: $TSFixMe) => {
        if (this.selectedOverlay && this.selectedOverlay.get('dragging') === true) {
            this.dragPan.setActive(true);
            this.selectedOverlay.set('dragging', false);

            const labelBoxData = this.selectedOverlay.get('labelBoxData');

            if (this.positionChanged) {
                this.positionChanged = false;

                const { extent } = labelBoxData;
                const deltaLon = evt.coordinate[0] - labelBoxData.box_lonlat[0];
                const deltaLat = evt.coordinate[1] - labelBoxData.box_lonlat[1];

                const newExtent = [
                    +extent[0] + deltaLon,
                    +extent[1] + deltaLat,
                    +extent[2] + deltaLon,
                    +extent[3] + deltaLat
                ];

                const updatedLabelBoxData = {
                    ...labelBoxData,
                    extent: newExtent,
                    box_lonlat: evt.coordinate
                };

                this.selectedOverlay.set('labelBoxData', updatedLabelBoxData);

                this.notifyObservers(TOOL_EVENT.UPDATE_LABEL_BOX, {
                    ...updatedLabelBoxData,
                    action: LABEL_ACTIONS.EDITED
                });
            }
        }
    };

    editLabelBox = () => {
        if (!this.selectedOverlay || this.domElements.container.style.display === 'block') return;

        this.mapObj.map.removeInteraction(this.mouseWheelZoom);
        const resolution = this.mapObj.map.getView().getResolution();
        const scaleFactor = 1 / resolution;

        this.draw.setActive(false);
        this.dragPan.setActive(false);
        const { text, text_color, text_size, box_color, width, height, box_lonlat } =
            this.selectedOverlay.get('labelBoxData');
        toolController.dispatchEvent(new CustomEvent('label-box-text', { detail: text }));

        this.lonLat = box_lonlat;
        this.editedOverlay = this.selectedOverlay;
        this.mapObj.map.removeOverlay(this.selectedOverlay);

        const boxCoordinates = this.mapObj.map.getPixelFromCoordinate(box_lonlat);
        const mapContainer = document.getElementById('map');
        // @ts-expect-error TS(2531): Object is possibly 'null'.
        const mapContainerRect = mapContainer.getBoundingClientRect();

        this.domElements.textArea.value = text;
        this.domElements.container.style.left = `${parseInt(mapContainerRect.left + boxCoordinates[0], 10)}px`;
        this.domElements.container.style.top = `${parseInt(mapContainerRect.top + boxCoordinates[1], 10)}px`;
        this.domElements.textArea.style.width = `${width * scaleFactor}px`;
        this.domElements.textArea.style.height = `${height * scaleFactor}px`;
        this.domElements.textArea.style.backgroundColor = box_color;
        this.domElements.textArea.style.color = text_color;
        this.domElements.textArea.style.fontSize = `${text_size}px`;
        this.domElements.textColor.value = text_color;
        this.domElements.textColorSelector.style.backgroundColor = text_color;
        this.domElements.textSize.value = text_size;
        this.domElements.textBoxColor.value = box_color;
        this.domElements.textBoxColorSelector.style.fill = 'black';
        this.domElements.deleteBtn.style.display = 'block';
        this.domElements.copyBtn.style.display = 'block';
        this.domElements.container.style.display = 'block';
        this.domElements.textArea.focus();
    };

    addNewLabelBox() {
        const resolution = this.mapObj.map.getView().getResolution();
        const scaleFactor = 1 / resolution;
        const textAreaRect = this.domElements.textArea.getBoundingClientRect();
        this.textareaWidthAdjusted =
            textAreaRect.width + (textAreaRect.width - textAreaRect.width * scaleFactor) / scaleFactor;
        this.textareaHeightAdjusted =
            textAreaRect.height + (textAreaRect.height - textAreaRect.height * scaleFactor) / scaleFactor;

        if (this.editedOverlay) {
            if (this.mouseWheelZoom) this.mapObj.map.addInteraction(this.mouseWheelZoom);

            const labelBoxData = {
                ...this.editedOverlay.get('labelBoxData'),
                ...this.getLabelData(),
                width: textAreaRect.width,
                height: textAreaRect.height,
                action: LABEL_ACTIONS.EDITED
            };

            this.addLabelBox(labelBoxData);
        } else {
            const labelBoxData = {
                ...this.getLabelData(),
                id: uuid4(),
                width: textAreaRect.width,
                height: textAreaRect.height,
                action: LABEL_ACTIONS.CREATED
            };

            this.addLabelBox(labelBoxData);
        }
        this.hideContainer();
    }

    getLabelData() {
        const labelData = {
            text: this.domElements.textArea.value,
            text_color: this.domElements.textColor.value,
            text_size: this.domElements.textSize.value,
            box_color: this.domElements.textBoxColor.value
        };

        return labelData;
    }

    deleteLabelBox() {
        if (this.selectedOverlay) {
            const data = { ...this.selectedOverlay.get('labelBoxData'), action: LABEL_ACTIONS.DELETED };
            this.notifyObservers(TOOL_EVENT.UPDATE_LABEL_BOX, data);
            this.mapObj.map.removeOverlay(this.selectedOverlay);
            this.selectedOverlay = null;
            this.editedOverlay = null;
            this.hideContainer();
        }
    }

    copyLabelBox() {
        if (this.selectedOverlay) {
            this.copiedLabelBoxData = { ...this.editedOverlay.get('labelBoxData') };
            this.hideContainer();
        }
    }

    addWithListeners = (labelData: $TSFixMe) => {
        const divElement = document.createElement('div');

        const resolution = this.mapObj.map.getView().getResolution();
        const scaleFactor = 1 / resolution;

        divElement.classList.add('label-box-overlay');
        divElement.innerHTML = labelData.text;
        divElement.style.width = `${labelData.width * scaleFactor}px`;
        divElement.style.height = `${labelData.height * scaleFactor}px`;
        divElement.style.color = labelData.text_color;
        divElement.style.fontSize = `${labelData.text_size}px`;
        divElement.style.backgroundColor = labelData.box_color;

        const overlay = new Overlay({
            position: labelData?.box_lonlat,
            element: divElement,
            stopEvent: false,
            // @ts-expect-error TS(2345): Argument of type '{ position: any; element: HTMLDi... Remove this comment to see the full error message
            dragging: false
        });

        overlay.set('labelBoxData', labelData);
        this.mapObj.map.addOverlay(overlay);
        outputMap.updateFontSize(overlay.getElement(), labelData.text_size);

        divElement.addEventListener('mouseenter', this.handleMouseEnter);
        divElement.addEventListener('mouseout', this.handleMouseOut);
        divElement.addEventListener('dblclick', this.editLabelBox);
        divElement.addEventListener('mousedown', this.handleMouseDown);
    };

    hideContainer() {
        if (this.editedOverlay) {
            this.mapObj.map.addOverlay(this.editedOverlay);
            this.domElements.deleteBtn.style.display = 'none';
            this.domElements.copyBtn.style.display = 'none';
            this.editedOverlay = null;
        }
        if (this.mouseWheelZoom) this.mapObj.map.addInteraction(this.mouseWheelZoom);
        this.draw.setActive(true);
        this.dragPan.setActive(true);
        this.lonLat = null;
        this.selectedOverlay = null;
        this.domElements.container.style.display = 'none';
        this.domElements.textArea.value = '';
        changeMapCursor(false, '', 'crosshair');
    }

    toggleLabelBoxes(val: $TSFixMe) {
        const overlays = this.mapObj.map.getOverlays().getArray();

        overlays.forEach((overlay: $TSFixMe) => {
            if (overlay.get('labelBoxData')) {
                overlay.getElement().style.display = val ? 'flex' : 'none';
            }
        });
    }

    removeOverlayEventListeners() {
        const overlays = this.mapObj.map.getOverlays().getArray();
        overlays.forEach((overlay: $TSFixMe) => {
            if (overlay.get('labelBoxData')) {
                overlay.getElement().removeEventListener('mouseenter', this.handleMouseEnter);
                overlay.getElement().removeEventListener('mouseout', this.handleMouseOut);
                overlay.getElement().removeEventListener('dblclick', this.editLabelBox);
                overlay.getElement().removeEventListener('mousedown', this.handleMouseDown);
            }
        });
    }

    off() {
        if (this.isToolActive) {
            this.removeOverlayEventListeners();
            this.hideContainer();
            this.isToolActive = false;
        }
        if (this.draw) {
            this.mapObj.map.removeInteraction(this.draw);
            this.draw.un('drawend', this.handleDrawEnd);
        }
        this.mapObj.map.un('singleclick', this.onMapClick);
        this.mapObj.map.un('pointermove', this.handlePointerMove);
        this.mapObj.map.un('pointerup', this.handlePointerUp);
        const mapContainer = document.getElementById('map');
        if (mapContainer) {
            mapContainer.style.cursor = 'default';
        }
        if (this.dblClickZoom) {
            this.mapObj.map.addInteraction(this.dblClickZoom);
            this.dblClickZoom = null;
        }
        if (this.mouseWheelZoom) {
            this.mapObj.map.addInteraction(this.mouseWheelZoom);
            this.mouseWheelZoom = null;
        }
        this.selectedOverlay = null;
        this.copiedLabelBoxData = null;
        document.removeEventListener('keydown', this.duplicateOverlay);
    }
}

export default LabelBox;
