import axios from 'axios';
import message from 'antd/es/message';
// @ts-expect-error TS(7016): Could not find a declaration file for module 'pako... Remove this comment to see the full error message
import { gzip, inflate } from 'pako';
import { BASE_URL } from '../Constants/Urls';
import { IFRAME_ERROR_TYPE, KNOWN_EXCEPTIONS } from '../Constants/Constant';
import { storage } from '../Stores/AppStore';
import { postMessageToReferrer } from './HelperFunctions';
import { SESSION_EXPIRED } from '../Constants/Messages';

/**
 * @name makeURL
 * @function
 * @description Used to create URL with Base Url
 * @param {String} URL API end point URL
 */
export function makeURL(URL: $TSFixMe) {
    return BASE_URL + URL;
}
export function getTokenType() {
    // @ts-expect-error TS(2339): Property 'isEmbed' does not exist on type 'Window ... Remove this comment to see the full error message
    if (storage.get('accelerateToken') || window.isEmbed) {
        return 'Bearer ';
    }
    return 'Token ';
}

/**
 * @name getAuthToken
 * @function
 * @description Returns token from storage
 */
export function getAuthToken() {
    let returnValue = null;
    // @ts-expect-error TS(2339): Property 'isEmbed' does not exist on type 'Window ... Remove this comment to see the full error message
    if (window.isEmbed) {
        returnValue = storage.get('api_key');
    } else if (storage.get('accelerateToken')) {
        returnValue = storage.get('accelerateToken');
    } else if (storage.get('token')) {
        returnValue = storage.get('token');
    }
    return returnValue;
}

/**
 * @name multipartAPI
 * @function
 * @description Used to call API with multipart data
 * @param {String} URL API URL
 * @param {Object} data Source data
 */
export function multipartAPI(
    URL: $TSFixMe,
    { data, prefix = '', params = {}, method = 'POST', onUploadProgress = () => {}, cancelToken }: $TSFixMe = {}
) {
    return new Promise((resolve, reject) => {
        axios({
            method,
            url: makeURL(prefix + URL),
            params,
            data,
            headers: {
                Authorization: getTokenType() + getAuthToken(),
                'Content-Type': 'multipart/form-data'
            },
            onUploadProgress,
            cancelToken
        })
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                reject(err);
                handleErrorCode(err);
            });
    });
}

/**
 * @name postAPI
 * @function
 * @description Used to call API with POST method
 * @param {String} URL API URL
 * @param {Object} data Source data
 */
export function postAPI(
    URL: $TSFixMe,
    { data = {}, prefix = '', cancelToken = null, isGzip = false, params = {}, onUploadProgress = () => {} } = {}
) {
    return new Promise((resolve, reject) => {
        // @ts-expect-error TS(2769): No overload matches this call.
        axios({
            method: 'POST',
            url: makeURL(prefix + URL),
            params,
            data: isGzip ? gzip(JSON.stringify(data)) : data,
            cancelToken,
            onUploadProgress,
            headers: {
                Accept: 'application/json; version=1.0',
                Authorization: getTokenType() + getAuthToken(),
                'Content-Encoding': 'gzip'
            }
        })
            .then(res => {
                let { data: _data } = res;
                if (res.headers['content-type'] === 'application/gzip') {
                    _data = gzipToJson(_data);
                }
                resolve(_data);
            })
            .catch(err => {
                reject(err);
                handleErrorCode(err);
            });
    });
}
/**
 * @name patchAPI
 * @function
 * @description Used to call API with patch(default) method
 * @param {String} URL API URL
 * @param {Object} data Source data
 */
export function patchAPI(URL: $TSFixMe, { data = {}, prefix = '', params = {}, method = 'PATCH' } = {}) {
    return new Promise((resolve, reject) => {
        // @ts-expect-error TS(2769): No overload matches this call.
        axios({
            method,
            url: makeURL(prefix + URL),
            params,
            data,
            headers: {
                Authorization: getTokenType() + getAuthToken(),
                'Content-Encoding': 'gzip'
            }
        })
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                reject(err);
                handleErrorCode(err);
            });
    });
}
/**
 * @name getAPI
 * @function
 * @description Used to call API with GET method
 * @param {String} URL API URL
 * @param {Object} params Source data
 * @param {Object} options options
 */
export function getAPI(URL: $TSFixMe, { params = {}, prefix = '', options = {}, c_url = false } = {}) {
    return new Promise((resolve, reject) => {
        axios({
            method: 'GET',
            url: c_url ? URL : makeURL(prefix + URL),
            params,
            // @ts-expect-error TS(2339): Property 'cancelToken' does not exist on type '{}'... Remove this comment to see the full error message
            cancelToken: options?.cancelToken,
            headers: {
                Accept: 'application/json; version=1.0',
                Authorization: getTokenType() + getAuthToken()
            }
        })
            .then(res => {
                let { data } = res;
                if (res.headers['content-type'] === 'application/gzip') {
                    data = gzipToJson(data);
                }

                resolve(data);
            })
            .catch(err => {
                reject(err);

                if (
                    // @ts-expect-error TS(2339): Property 'skipErrorStatus' does not exist on type ... Remove this comment to see the full error message
                    err?.response?.status !== options?.skipErrorStatus &&
                    // @ts-expect-error TS(2339): Property 'skipErrorStatusList' does not exist on t... Remove this comment to see the full error message
                    !options?.skipErrorStatusList?.includes(err?.response?.status)
                ) {
                    handleErrorCode(err);
                }
            });
    });
}

/**
 * @name openAPI
 * @function
 * @description Used to login (Calls API without token)
 * @param {String} URL API URL
 * @param {Object} data Source data
 */
// eslint-disable-next-line func-names
export const openAPI = function (
    URL: $TSFixMe,
    data: $TSFixMe,
    method = 'POST',
    options = {},
    params = {},
    prefix = ''
) {
    return new Promise((resolve, reject) => {
        // @ts-expect-error TS(2769): No overload matches this call.
        axios({
            method,
            url: makeURL(prefix + URL),
            data,
            params
        })
            .then(res => {
                let { data: _data } = res;
                if (res.headers['content-type'] === 'application/gzip') {
                    _data = gzipToJson(_data);
                }
                resolve(_data);
            })
            .catch(err => {
                reject(err);

                // @ts-expect-error TS(2339): Property 'skipErrorStatus' does not exist on type ... Remove this comment to see the full error message
                if (err?.response?.status !== options?.skipErrorStatus) {
                    handleErrorCode(err);
                }
            });
    });
};

/**
 * @name thirdPartyAPI
 * @function
 * @description Used to call any third party API
 * @param {String} URL API URL
 */
export async function thirdPartyAPI(URL: $TSFixMe, data: $TSFixMe, method = 'GET') {
    // @ts-expect-error TS(2769): No overload matches this call.
    const response = await axios({
        method,
        url: URL,
        data
    });
    return response.data;
}

/**
 * @name interpolate
 * @function
 * @description Replace %s from a string to given number
 * @param {String} theString String with %s
 * @param {Array} argumentArray Array of numbers
 */
export function interpolate(theString: $TSFixMe, argumentArray: $TSFixMe) {
    const regex = /%s/;
    const regexFunc = function regexFunc(p: $TSFixMe, c: $TSFixMe) {
        return p.replace(regex, c);
    };
    return argumentArray.reduce(regexFunc, theString);
}

/**
 * @name gzipToJson
 * @function
 * @description converts gzip data to object
 * @param {gzip} data value
 */
export const gzipToJson = function gzipToJson(data: $TSFixMe) {
    return JSON.parse(inflate(Buffer.from(data, 'base64'), { to: 'string' }));
};

export const handleErrorCode = function handleErrorCode(err: $TSFixMe) {
    if (err?.response?.data?.errors?.length) {
        if (err.response.data.error_type === 'AuthenticationFailed') {
            // @ts-expect-error TS(2339): Property 'isEmbed' does not exist on type 'Window ... Remove this comment to see the full error message
            if (!window.isEmbed) {
                storage.remove('token');
                storage.remove('accelerateToken');
                window.location.href = '/';
            }
        }
        // Hide global error promt from known exceptions
        const knownErr = Object.values(KNOWN_EXCEPTIONS);
        if (knownErr.indexOf(err.response.data.error_type) === -1) {
            const errorDict = err.response.data.errors[0];
            let errMessage =
                errorDict.message ||
                (errorDict.errors && errorDict.errors.length && errorDict.errors[0].message) ||
                null;

            // If token expired display only one time
            if (errMessage === 'Invalid token.') {
                if (storage.get('invalid_token_message_displayed') !== '1') {
                    storage.set('invalid_token_message_displayed', '1');
                } else return;
                // @ts-expect-error TS(2339): Property 'isEmbed' does not exist on type 'Window ... Remove this comment to see the full error message
                if (window.isEmbed) {
                    document.dispatchEvent(new CustomEvent('token_expired', {}));
                    postMessageToReferrer({ error_type: IFRAME_ERROR_TYPE.TOKEN_EXPIRED });
                }
                errMessage = SESSION_EXPIRED;
            }

            // Handling nested errors
            if (errMessage) message.error(errMessage);
        }
    }
};
