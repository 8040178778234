class ChangedVectorTracker {
    changedLayers: $TSFixMe;

    mapObj: $TSFixMe;

    constructor(mapObj: $TSFixMe) {
        this.mapObj = mapObj;
        this.changedLayers = new Map();
    }

    getArray() {
        return Array.from(this.changedLayers.values());
    }

    clear() {
        this.changedLayers.clear();
    }

    push(key: $TSFixMe, value: $TSFixMe) {
        const mapVal = this.changedLayers.get(key);
        if (!mapVal) {
            this.changedLayers.set(key, new Set([value]));
        } else if (!mapVal.has(value)) {
            mapVal.add(value);
        }
    }

    // Whether {value} exists on the map key {key}
    hasValueAtKey(key: $TSFixMe, value: $TSFixMe) {
        return this.changedLayers.get(key)?.has(value);
    }

    // Whether Set is empty on the map key {key}
    isEmptyAtKey(key: $TSFixMe) {
        return !this.changedLayers.get(key)?.size;
    }

    getValuesByKey(key: $TSFixMe) {
        const mapVal = this.changedLayers.get(key);
        return mapVal ? Array.from(mapVal.values()) : [];
    }

    clearAtKey(key: $TSFixMe) {
        return this.changedLayers.delete(key);
    }
}

export default ChangedVectorTracker;
