import create from 'zustand';
import { devtools } from 'zustand/middleware';

import DeleteIcon from '../Icons/delete.svg';
import CutIcon from '../Icons/cut-tool.svg';
import CopyIcon from '../Icons/copy-tool.svg';
import PasteIcon from '../Icons/paste-tool.svg';
import RotateRightIcon from '../Icons/rotate-clockwise-tool.svg';
import RotateLeftIcon from '../Icons/rotate-counterclockwise-tool.svg';
import FlipHorizontalIcon from '../Icons/flip-horizontal-tool.svg';
import FlipVerticalIcon from '../Icons/flip-vertical-tool.svg';
import Locate from '../Icons/measurement-table/locate';
import { MENU_ITEMS_TITLE, TOOLS_ID } from '../Constants/Constant';
import {
    handleCallout,
    handleClipboard,
    handleDelete,
    handleFlipRotate,
    handleLocateMe,
    handlePaste,
    onClose
} from '../Utils/ContextMenuActions';

const initState = {
    position: { top: 0, left: 0 },
    feature: null,
    visible: false,
    onClose,
    calloutPopupVisibility: false,
    menuItems: [
        {
            title: MENU_ITEMS_TITLE.CUT,
            icon: CutIcon,
            seperator: false,
            childs: null,
            events: {
                click: (feature: $TSFixMe) => handleClipboard(feature, TOOLS_ID.CUT_TOOL)
            }
        },
        {
            title: MENU_ITEMS_TITLE.COPY,
            icon: CopyIcon,
            seperator: false,
            childs: null,
            events: {
                click: (feature: $TSFixMe) => handleClipboard(feature, TOOLS_ID.COPY_TOOL)
            }
        },
        {
            title: MENU_ITEMS_TITLE.PASTE,
            icon: PasteIcon,
            seperator: true,
            childs: null,
            events: {
                click: () => handlePaste({ toolId: TOOLS_ID.PASTE_TOOL, shortcutKey: true })
            }
        },
        {
            title: MENU_ITEMS_TITLE.LOCATE,
            icon: Locate,
            seperator: false,
            childs: null,
            events: {
                click: handleLocateMe
            }
        },
        {
            title: MENU_ITEMS_TITLE.MODIFY_CALLOUT,
            icon: CopyIcon,
            seperator: true,
            childs: null,
            events: {
                click: handleCallout
            }
        },
        {
            title: MENU_ITEMS_TITLE.FLIP,
            icon: FlipHorizontalIcon,
            seperator: false,
            childs: [
                {
                    title: MENU_ITEMS_TITLE.HORIZONTALLY,
                    icon: FlipHorizontalIcon,
                    seperator: false,
                    childs: null,
                    events: {
                        click: (feature: $TSFixMe) => handleFlipRotate(feature, TOOLS_ID.FLIP_HORIZONTAL)
                    }
                },
                {
                    title: MENU_ITEMS_TITLE.VERTICALLY,
                    icon: FlipVerticalIcon,
                    seperator: false,
                    childs: null,
                    events: {
                        click: (feature: $TSFixMe) => handleFlipRotate(feature, TOOLS_ID.FLIP_VERTICAL)
                    }
                }
            ],
            events: null
        },
        {
            title: MENU_ITEMS_TITLE.ROTATE,
            icon: RotateRightIcon,
            seperator: true,
            events: null,
            childs: [
                {
                    title: MENU_ITEMS_TITLE.CLOCKWISE,
                    icon: RotateRightIcon,
                    seperator: false,
                    childs: null,
                    events: {
                        click: (feature: $TSFixMe) => handleFlipRotate(feature, TOOLS_ID.ROTATE_CLOCKWISE)
                    }
                },
                {
                    title: MENU_ITEMS_TITLE.COUNTER_CLOCKWISE,
                    icon: RotateLeftIcon,
                    seperator: false,
                    childs: null,
                    events: {
                        click: (feature: $TSFixMe) => handleFlipRotate(feature, TOOLS_ID.ROTATE_COUNTER_CLOCKWISE)
                    }
                }
            ]
        },
        {
            title: MENU_ITEMS_TITLE.DELETE,
            icon: DeleteIcon,
            seperator: false,
            danger: true,
            childs: null,
            events: {
                click: (data: $TSFixMe) => handleDelete(data)
            }
        }
    ]
};

const reducer = (state: $TSFixMe, { type, payload = null }: $TSFixMe) => {
    switch (type) {
        case 'SET_CONTEXTMENU':
            return { ...state, ...payload };
        case 'SET_CALLOUT_POPUP_VISIBILITY':
            return { ...state, calloutPopupVisibility: payload };
        case 'RESET_CONTEXTMENU':
            return initState;
        default:
            return state;
    }
};

const store = (set: $TSFixMe) => ({
    ...initState,
    dispatch: (args: $TSFixMe) => set((state: $TSFixMe) => reducer(state, args))
});

export const useContextMenu = create(devtools(store));
