import Feature from 'ol/Feature';
import { getCenter } from 'ol/extent';
import { fromExtent } from 'ol/geom/Polygon';

import message from 'antd/es/message';

import { TOOLS_ID } from '../../../Constants/Constant';
import { Observer } from '../../../Utils/Observer';
import { layerTracker } from '../MapInit';
import { getWithoutPointSelectedFeatures } from '../../../Utils/olutils';
import { TOOL_EVENT } from '../../Output/Toolbar/ToolController';
import { useRequest } from '../../../Stores/Request';

class RotateTool extends Observer {
    bpLotExtent: $TSFixMe;

    lotFeature: $TSFixMe;

    mapObj: $TSFixMe;

    constructor(mapObj: $TSFixMe) {
        super();
        this.mapObj = mapObj;
        this.bpLotExtent = null;
        this.lotFeature = null;
    }

    on({ toolId }: $TSFixMe) {
        this.off();

        if (this.mapObj.isBlueprintMap) {
            const bpSheetExtent = this.mapObj.baseLayer.get('bp_page_extent');
            this.bpLotExtent = new Feature(fromExtent(bpSheetExtent));
        }

        let selectedFeatures = useRequest.getState()?.selectedFeatures;
        selectedFeatures = getWithoutPointSelectedFeatures(selectedFeatures);

        if (selectedFeatures.length) {
            selectedFeatures.forEach(f => {
                // @ts-expect-error TS(2339): Property 'getGeometry' does not exist on type 'nev... Remove this comment to see the full error message
                const geom = f.getGeometry();
                const originalCoords = geom.getCoordinates();
                geom.rotate(
                    toolId === TOOLS_ID.ROTATE_COUNTER_CLOCKWISE ? Math.PI / 2 : -Math.PI / 2,
                    getCenter(geom.getExtent())
                );

                // handling out_of_extent case
                const is_out_of_extent = this.mapObj.isGeometryOutOfLotBoundary({
                    geom,
                    boundary: this.mapObj.isBlueprintMap ? this.bpLotExtent : this.lotFeature
                });

                if (is_out_of_extent) {
                    message.error(
                        // @ts-expect-error TS(2339): Property 'get' does not exist on type 'never'.
                        `Oops! The feature with ID - ${f.get(
                            'id'
                        )} cannot be rotated because its boundaries can cross the sheet extent.`
                    );
                    geom.setCoordinates(originalCoords);
                } else {
                    // @ts-expect-error TS(2339): Property 'get' does not exist on type 'never'.
                    const layerId = f.get('layerId');
                    // Push layer in tracker
                    layerTracker.push(this.mapObj.getLayerName(layerId), layerId);
                }
            });
            if (layerTracker.getArray().length) {
                this.notifyObservers(TOOL_EVENT.ROTATE_TOOL);
            }
        }
    }

    off() {
        this.bpLotExtent = null;
        this.lotFeature = null;
    }
}

export default RotateTool;
