import Feature from 'ol/Feature';
import { LineString, Polygon } from 'ol/geom';
import { getCenter } from 'ol/extent';
import { fromExtent } from 'ol/geom/Polygon';

import message from 'antd/es/message';

import { GEOMETRY_TYPE_STRING, TOOLS_ID } from '../../../Constants/Constant';
import { Observer } from '../../../Utils/Observer';
import { getWithoutPointSelectedFeatures } from '../../../Utils/olutils';
import { layerTracker } from '../MapInit';
import { TOOL_EVENT } from '../../Output/Toolbar/ToolController';
import { useRequest } from '../../../Stores/Request';

class FlipTool extends Observer {
    bpLotExtent: $TSFixMe;

    lotFeature: $TSFixMe;

    mapObj: $TSFixMe;

    constructor(mapObj: $TSFixMe) {
        super();
        this.mapObj = mapObj;
        this.bpLotExtent = null;
        this.lotFeature = null;
    }

    on({ toolId }: $TSFixMe) {
        this.off();

        if (this.mapObj.isBlueprintMap) {
            const bpSheetExtent = this.mapObj.baseLayer.get('bp_page_extent');
            this.bpLotExtent = new Feature(fromExtent(bpSheetExtent));
        }

        let selectedFeatures = useRequest.getState()?.selectedFeatures;
        selectedFeatures = getWithoutPointSelectedFeatures(selectedFeatures);
        if (selectedFeatures.length) {
            selectedFeatures.forEach(f => {
                // @ts-expect-error TS(2339): Property 'getGeometry' does not exist on type 'nev... Remove this comment to see the full error message
                const geom = f.getGeometry();
                const originalGeom = geom.clone();
                const center = getCenter(geom.getExtent());

                if (toolId === TOOLS_ID.FLIP_HORIZONTAL) {
                    // Flip the polygon horizontally
                    geom.applyTransform((coords: $TSFixMe, _: $TSFixMe, stride: $TSFixMe) => {
                        for (let i = 0; i < coords.length; i += stride) {
                            coords[i] = 2 * center[0] - coords[i];
                        }
                    });

                    // @ts-expect-error TS(2339): Property 'setGeometry' does not exist on type 'nev... Remove this comment to see the full error message
                    f.setGeometry(geom);
                } else {
                    // Flip the polygon vertically
                    const coords = geom.getCoordinates();
                    let flippedGeom;
                    if (geom.getType() === GEOMETRY_TYPE_STRING.POLYGON) {
                        const flippedCoords = coords.map((ring: $TSFixMe) =>
                            ring.map((point: $TSFixMe) => [point[0], 2 * center[1] - point[1]])
                        );
                        // Create a new polygon geometry with the flipped coords
                        flippedGeom = new Polygon(flippedCoords);
                    } else if (geom.getType() === GEOMETRY_TYPE_STRING.LINESTRING) {
                        const flippedCoords = coords.map((point: $TSFixMe) => [point[0], 2 * center[1] - point[1]]);
                        // Create a new LineString geometry with the flipped coordinates
                        flippedGeom = new LineString(flippedCoords);
                    }

                    // @ts-expect-error TS(2339): Property 'setGeometry' does not exist on type 'nev... Remove this comment to see the full error message
                    f.setGeometry(flippedGeom);
                }

                // handling out_of_extent case
                const is_out_of_extent = this.mapObj.isGeometryOutOfLotBoundary({
                    // @ts-expect-error TS(2339): Property 'getGeometry' does not exist on type 'nev... Remove this comment to see the full error message
                    geom: f.getGeometry(),
                    boundary: this.mapObj.isBlueprintMap ? this.bpLotExtent : this.lotFeature
                });

                if (is_out_of_extent) {
                    message.error(
                        // @ts-expect-error TS(2339): Property 'get' does not exist on type 'never'.
                        `Oops! The feature with ID - ${f.get(
                            'id'
                        )} cannot be rotated because its boundaries can cross the sheet extent.`
                    );
                    // @ts-expect-error TS(2339): Property 'setGeometry' does not exist on type 'nev... Remove this comment to see the full error message
                    f.setGeometry(originalGeom);
                } else {
                    // @ts-expect-error TS(2339): Property 'get' does not exist on type 'never'.
                    const layerId = f.get('layerId');
                    // Push layer in tracker
                    layerTracker.push(this.mapObj.getLayerName(layerId), layerId);
                }
            });
            if (layerTracker.getArray().length) {
                this.notifyObservers(TOOL_EVENT.FLIP_TOOL);
            }
        }
    }

    off() {
        this.bpLotExtent = null;
        this.lotFeature = null;
    }
}

export default FlipTool;
