import { v4 as uuid4 } from 'uuid';
import { captureException } from '@sentry/react';

import Style from 'ol/style/Style';
import Fill from 'ol/style/Fill';
import Text from 'ol/style/Text';
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import Point from 'ol/geom/Point';
import Feature from 'ol/Feature';
import { containsCoordinate } from 'ol/extent';
import { fromLonLat, toLonLat, transformExtent } from 'ol/proj';

import { LABEL_ACTIONS, LAYER_INDEX, MAP_LAYERS } from '../../../Constants/Constant';
import { adjustOverlayPosition, changeMapCursor, getOutputData } from '../../../Utils/HelperFunctions';
import { layerTracker, outputMap } from '../MapInit';
import { getHighlightTextStyle } from '../MapBase';
import { Observer } from '../../../Utils/Observer';
import { TOOL_EVENT } from '../../Output/Toolbar/ToolController';

class Labels extends Observer {
    activeCoords: $TSFixMe;

    domElements: $TSFixMe;

    hoveredFeature: $TSFixMe;

    invalidSpace: $TSFixMe;

    labelVisibility: $TSFixMe;

    labelsLayer: $TSFixMe;

    mapObj: $TSFixMe;

    requestId: $TSFixMe;

    selectedFeature: $TSFixMe;

    constructor(mapObj: $TSFixMe) {
        super();
        this.mapObj = mapObj;
        this.labelsLayer = null;
        this.domElements = null;
        this.activeCoords = [];
        this.selectedFeature = null;
        this.requestId = null;
        this.invalidSpace = false;
    }

    on({ requestId }: $TSFixMe) {
        this.mapObj.map.on('pointermove', this.onMapHover);
        this.mapObj.map.on('singleclick', this.onMapClick);

        this.requestId = requestId;
        this.activeCoords = [];
        this.domElements = {
            container: document.getElementById('label-container'),
            input: document.getElementById('label-input'),
            color: document.getElementById('label-color'),
            size: document.getElementById('label-size'),
            delete: document.getElementById('label-delete')
        };
    }

    addLabels(labels: $TSFixMe) {
        try {
            const prevLayer = this.mapObj.getLayerById(MAP_LAYERS.LABELS);
            if (prevLayer) this.mapObj.removeLayer(prevLayer);

            this.labelsLayer = new VectorLayer({
                source: new VectorSource({ wrapX: false }),
                // @ts-expect-error TS(2345): Argument of type '{ source: VectorSource<Geometry>... Remove this comment to see the full error message
                id: MAP_LAYERS.LABELS,
                name: MAP_LAYERS.LABELS,
                zIndex: LAYER_INDEX.LABEL,
                layerData: { name: MAP_LAYERS.LABELS },
                style: this.getFeatureStyle
            });

            this.mapObj.addLayer(this.labelsLayer);

            if (labels.length) {
                for (let i = 0; i < labels.length; i++) {
                    this.addLabel(labels[i]);
                }
            }
        } catch (err) {
            captureException(err);
        }
    }

    onMapClick = (e: $TSFixMe) => {
        if (this.invalidSpace) return;

        this.selectedFeature = this.mapObj.map.forEachFeatureAtPixel(
            e.pixel,
            (_feature: $TSFixMe, _layer: $TSFixMe) => {
                if (_layer.get('id') === MAP_LAYERS.LABELS) {
                    return _feature;
                }
                return null;
            }
        );
        if (this.selectedFeature) {
            const labelData = this.selectedFeature.get('labelData');
            this.domElements.input.value = labelData.text;
            this.domElements.size.value = labelData.label_style.size;
            this.domElements.color.value = labelData.label_style.color;
            this.domElements.delete.style.display = 'inline';
            this.activeCoords = [labelData.lon, labelData.lat];
        } else {
            // set coords when Label is created
            this.activeCoords = this.mapObj.isBlueprintMap ? e.coordinate : toLonLat(e.coordinate);
            this.domElements.delete.style.display = 'none';
            this.domElements.size.value = 12;
        }

        const { container } = this.domElements;
        container.style.display = 'block';
        const { offsetWidth: overlayWidth, offsetHeight: overlayHeight } = container || {};
        const { pageX, pageY } = e.originalEvent || {};

        const [positionX, positionY] = adjustOverlayPosition({
            pageX,
            pageY,
            overlayWidth,
            overlayHeight
        });

        // @ts-expect-error TS(2345): Argument of type 'number' is not assignable to par... Remove this comment to see the full error message
        container.style.left = `${parseInt(positionX, 10)}px`;
        // @ts-expect-error TS(2345): Argument of type 'number' is not assignable to par... Remove this comment to see the full error message
        container.style.top = `${parseInt(positionY, 10)}px`;
    };

    onMapHover = (e: $TSFixMe) => {
        // @ts-expect-error TS(2571): Object is of type 'unknown'.
        const image = getOutputData()?.aoi?.image;
        if (image) {
            let imageExtent = [image.left, image.bottom, image.right, image.top];
            imageExtent = transformExtent(imageExtent, 'EPSG:4326', 'EPSG:3857');
            this.invalidSpace = !containsCoordinate(imageExtent, e.coordinate);
        }

        changeMapCursor(this.invalidSpace, 'not-allowed', 'text');
        if (this.hoveredFeature) this.hoveredFeature.setStyle(null);
        if (!this.invalidSpace) {
            this.mapObj.map.forEachFeatureAtPixel(e.pixel, (_feature: $TSFixMe, _layer: $TSFixMe) => {
                if (_layer.get('id') === MAP_LAYERS.LABELS) {
                    this.hoveredFeature = _feature;
                    const style = _layer.getStyle()?.(_feature);
                    _feature.setStyle(getHighlightTextStyle(style));
                    changeMapCursor(true, 'pointer');
                    return true;
                }
                return false;
            });
        }
    };

    addOrEditLabel() {
        if (this.selectedFeature) {
            this.editLabel();
        } else {
            this.addNewLabel();
        }
    }

    editLabel() {
        const labelData = { ...this.getLabelData(), id: this.selectedFeature.get('id'), action: LABEL_ACTIONS.EDITED };

        this.selectedFeature.set('labelData', labelData);
        this.hideContainer();
        this.selectedFeature = null;

        layerTracker.push(MAP_LAYERS.LABELS, labelData);
        this.notifyObservers(TOOL_EVENT.LABEL_UPDATED);
    }

    getRequestParams() {
        let params = {};
        if (this.mapObj.isBlueprintMap) {
            const worksheet_id = this.mapObj.baseLayer?.getProperties()?.bp_page_id;
            params = { worksheet_id };
        }
        return params;
    }

    getFeatureStyle(feature: $TSFixMe) {
        const labelData = feature.get('labelData');
        const font = `${labelData.label_style.size}px sans-serif`;
        const style = new Style({
            text: new Text({
                font,
                text: labelData.text,
                fill: new Fill({ color: labelData.label_style.color || 'black' }),
                textAlign: 'left'
            })
        });
        return style;
    }

    getLabelData() {
        const labelData = {
            lon: this.activeCoords[0],
            lat: this.activeCoords[1],
            text: this.domElements.input.value,
            label_style: {
                size: this.domElements.size.value || 12,
                color: this.domElements.color.value
            }
        };
        return labelData;
    }

    addNewLabel() {
        const labelData = { ...this.getLabelData(), id: uuid4(), action: LABEL_ACTIONS.CREATED };

        this.addLabel(labelData);
        this.hideContainer();

        layerTracker.push(MAP_LAYERS.LABELS, labelData);
        this.notifyObservers(TOOL_EVENT.LABEL_UPDATED);
    }

    deleteLabel({ labelId = null, deleteFeatureTool = false } = {}) {
        if (!labelId) labelId = this.selectedFeature.get('id');

        if (this.selectedFeature) {
            this.labelsLayer.getSource().removeFeature(this.selectedFeature);
            this.selectedFeature = null;
            this.hideContainer();
        }

        if (!deleteFeatureTool) {
            layerTracker.push(MAP_LAYERS.LABELS, labelId);
            this.notifyObservers(TOOL_EVENT.LABEL_UPDATED, labelId);
        }
    }

    resetContainerValue() {
        this.domElements.input.value = '';
        this.domElements.size.value = '';
        this.domElements.color.value = '#000000';
        if (this.hoveredFeature) this.hoveredFeature.setStyle(null);
    }

    addLabel(labelData: $TSFixMe) {
        const coordinates = [labelData.lon, labelData.lat];
        const feature = new Feature({
            geometry: new Point(this.mapObj.isBlueprintMap ? coordinates : fromLonLat(coordinates)),
            id: labelData.id,
            labelData,
            isLabel: true
        });
        feature.setProperties({ layerId: labelData.id });
        this.labelsLayer.getSource().addFeature(feature);
    }

    hideContainer() {
        this.domElements.container.style.display = 'none';
        this.resetContainerValue();
    }

    setLabelVisibility(val: $TSFixMe) {
        this.labelVisibility = val;
        outputMap.setVisibility(MAP_LAYERS.LABELS, val);
    }

    off() {
        this.mapObj.map.un('pointermove', this.onMapHover);
        this.mapObj.map.un('singleclick', this.onMapClick);
        const mapContainer = document.getElementById('map');
        if (mapContainer) {
            mapContainer.style.cursor = 'default';
        }
    }
}

export default Labels;
