import MapBase from './MapBase';
import OutputMap from './OutputMap';
import DropPin from './DropPin';
import TileImagery from './TileImagery';
import ChangedVectorTracker from './ChangedVectorTracker';
import HistoryManager from './HistoryManager';
import ToolController from '../Output/Toolbar/ToolController';
import EditParcel from './Tools/EditParcel';
import ScaleLine from './Tools/ScaleLine';

export const mapObj = new MapBase();
export const History = new HistoryManager(mapObj);
export const outputMap = new OutputMap(mapObj);
export const dropPin = new DropPin(mapObj);
export const tileImagery = new TileImagery(mapObj);
export const layerTracker = new ChangedVectorTracker(mapObj);
export const toolController = new ToolController(mapObj);
export const editParcel = new EditParcel(mapObj);
export const scaleLine = new ScaleLine(mapObj);

export const initOlMap = (isBlueprint: boolean, countryCode: string, enableRightClickDrag: boolean) => {
    if (isBlueprint) {
        mapObj.initBlueprint(enableRightClickDrag);
    } else {
        mapObj.init(countryCode, enableRightClickDrag);
    }
};
