import React from 'react';

const Locate = ({ fill = '#4D4D4D', ...props }) => {
    return (
        <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <path
                d='M10.8333 0.834839L10.8341 3.38651C12.3026 3.57217 13.6676 4.24103 14.7142 5.28777C15.7608 6.3345 16.4295 7.69963 16.6149 9.16817H19.1666V10.8348L16.6149 10.8357C16.4293 12.3041 15.7606 13.669 14.714 14.7156C13.6674 15.7621 12.3025 16.4309 10.8341 16.6165L10.8333 19.1682H9.16658V16.6165C7.69805 16.4311 6.33292 15.7624 5.28618 14.7158C4.23944 13.6692 3.57058 12.3042 3.38492 10.8357L0.833252 10.8348V9.16817H3.38492C3.57041 7.69951 4.2392 6.33428 5.28595 5.28753C6.3327 4.24078 7.69792 3.57199 9.16658 3.38651V0.834839H10.8333ZM9.99992 5.0015C8.67384 5.00151 7.40207 5.52829 6.46438 6.46597C5.5267 7.40365 4.99992 8.67542 4.99992 10.0015C4.99992 11.3276 5.5267 12.5994 6.46438 13.537C7.40207 14.4747 8.67384 15.0015 9.99992 15.0015C11.326 15.0015 12.5978 14.4747 13.5355 13.537C14.4731 12.5994 14.9999 11.3276 14.9999 10.0015C14.9999 8.67542 14.4731 7.40365 13.5355 6.46597C12.5978 5.52829 11.326 5.00151 9.99992 5.0015ZM9.99992 8.33484C10.4419 8.33484 10.8659 8.51043 11.1784 8.82299C11.491 9.13555 11.6666 9.55948 11.6666 10.0015C11.6666 10.4435 11.491 10.8675 11.1784 11.18C10.8659 11.4926 10.4419 11.6682 9.99992 11.6682C9.55789 11.6682 9.13397 11.4926 8.82141 11.18C8.50885 10.8675 8.33325 10.4435 8.33325 10.0015C8.33325 9.55948 8.50885 9.13555 8.82141 8.82299C9.13397 8.51043 9.55789 8.33484 9.99992 8.33484Z'
                fill={fill}
            />
        </svg>
    );
};

export default Locate;
