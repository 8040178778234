import { Observer } from '../../../Utils/Observer';
import { TOOL_EVENT } from '../../Output/Toolbar/ToolController';
import { useRequest } from '../../../Stores/Request';
import { getClipboardPayload } from '../../../Utils/olutils';

class LoadClipboard extends Observer {
    // eslint-disable-next-line no-useless-constructor
    constructor() {
        super();
    }

    on({
        toolId
    }: $TSFixMe) {
        this.off();

        const selectedFeatures = useRequest.getState()?.selectedFeatures;
        if (selectedFeatures.length) {
            const payload = getClipboardPayload({ features: selectedFeatures, toolId, changeStyle: true });

            this.notifyObservers(TOOL_EVENT.LOAD_CLIPBOARD, payload);
        }
    }

    off() {}
}

export default LoadClipboard;
