import { Select } from 'ol/interaction';
import { pointerMove } from 'ol/events/condition';

import { MAP_LAYERS, REQUEST_STATUS_ENUM, deleteNonHighLightLayers } from '../../../Constants/Constant';
import { changeMapCursor, isNumericalLayer } from '../../../Utils/HelperFunctions';
import { Observer } from '../../../Utils/Observer';
import { TOOL_EVENT } from '../../Output/Toolbar/ToolController';
import { EDIT_STYLE, getHighlightTextStyle, HIGHLIGHT_STYLE_NUMERICAL, highlightStyle } from '../MapBase';
import { editParcel, History, layerTracker } from '../MapInit';

class DeleteFeature extends Observer {
    hover: $TSFixMe;

    hoveredFeature: $TSFixMe;

    isParcel: $TSFixMe;

    mapObj: $TSFixMe;

    parcelStyle: $TSFixMe;

    targetLayer: $TSFixMe;

    constructor(mapObj: $TSFixMe) {
        super();
        this.mapObj = mapObj;
        this.hoveredFeature = null;
        this.targetLayer = null;
        this.isParcel = null;
        this.parcelStyle = null;
    }

    on({ requestStatus }: $TSFixMe) {
        this.isParcel = requestStatus === REQUEST_STATUS_ENUM.DRAFT;
        if (this.isParcel) {
            if (!editParcel.getFeatures()?.length) throw new Error('PARCEL_NOT_FOUND');
            const parcelLayer = this.mapObj.getParcelLayer(true);
            this.parcelStyle = parcelLayer.getStyle();
            parcelLayer.setStyle(EDIT_STYLE);
            History.setIsParcel(this.isParcel);
        }
        this.mapObj.map.on('pointermove', this.changeCursor);

        this.hover = new Select({
            condition: pointerMove,
            toggleCondition: () => false,
            filter: (feature, layer) => this.isParcel || !deleteNonHighLightLayers(layer?.get('name')),

            style: feature => {
                let layer = this.mapObj.getLayerById(feature.get('layerId'));
                if (feature.get('isLabel')) {
                    layer = this.mapObj.getLayerById(MAP_LAYERS.LABELS);
                    const style = layer?.getStyle()?.(feature);
                    return getHighlightTextStyle(style);
                }
                return layer && isNumericalLayer(layer)
                    ? HIGHLIGHT_STYLE_NUMERICAL
                    : // @ts-expect-error TS(2322): Type 'FeatureLike' is not assignable to type 'null... Remove this comment to see the full error message
                      highlightStyle({ layer, feature });
            }
        });
        this.mapObj.map.addInteraction(this.hover);

        this.mapObj.map.on('singleclick', this.deleteFeatureOnClick);
    }

    changeCursor = (e: $TSFixMe) => {
        changeMapCursor(false, 'not-allowed');
        this.mapObj.map.forEachFeatureAtPixel(e.pixel, (_feature: $TSFixMe, _layer: $TSFixMe) => {
            if (this.isParcel || !deleteNonHighLightLayers(_layer?.get('name'))) {
                // @ts-expect-error TS(2531): Object is possibly 'null'.
                document.getElementById('map').style.cursor = 'pointer';
                return true;
            }
            return false;
        });
    };

    deleteFeatureOnClick = (e: $TSFixMe) => {
        this.mapObj.map.forEachFeatureAtPixel(e.pixel, (_feature: $TSFixMe, _layer: $TSFixMe) => {
            // labels will not be deleted from this tool since, it is not deleting it from API
            // also undo redo will still not work if we call api to delete label
            if (_layer.get('name') === MAP_LAYERS.ZONE) return false;
            if (this.isParcel || !deleteNonHighLightLayers(_layer?.get('name'))) {
                _layer.getSource().removeFeature(_feature);

                // Push layer in tracker
                const layerId = _feature.get('layerId');
                layerTracker.push(_layer.get('name'), layerId);
                // @ts-expect-error TS(2554): Expected 1 arguments, but got 0.
                History.add();

                if (!this.isParcel) this.notifyObservers(TOOL_EVENT.DELETE_FEATURE);
                return true;
            }
            return false;
        });
    };

    off() {
        this.mapObj.map.un('pointermove', this.changeCursor);
        const parcelLayer = this.mapObj.getParcelLayer();
        if (this.parcelStyle && parcelLayer) {
            parcelLayer.setStyle(this.parcelStyle);
        }
        this.hover && this.mapObj.map.removeInteraction(this.hover);
        this.mapObj.map.un('singleclick', this.deleteFeatureOnClick);
    }
}
export default DeleteFeature;
